<template>
    <ideo-form-group :label="$t('[[[Moduł dynamiczny]]]')" :invalid-feedback="form.$errors.first('moduleId')" :state="form.$errors.state('moduleId')" required>
        <ideo-form-select v-model="form.settings.moduleId" type="text" name="moduleId" :options="modules"></ideo-form-select>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Sekcja modułu]]]')" :invalid-feedback="form.$errors.first('sectionId')" :state="form.$errors.state('sectionId')" required>
        <ideo-form-select v-model="form.settings.sectionId" type="text" name="sectionId" :options="sections" :disabled="!form.settings.moduleId"></ideo-form-select>
    </ideo-form-group>
    <ideo-form-group
        :label="$t('[[[Kolor tekstu]]]')"
        :invalid-feedback="form.$errors.first('textColor')"
        :state="form.$errors.state('textColor')"
    >
        <ideo-form-input v-model="form.settings.textColor" type="color" name="textColor" v-focus :maxlength="20"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group
        :label="$t('[[[Kolor tła]]]')"
        :invalid-feedback="form.$errors.first('settings.backgroundColor')"
        :state="form.$errors.state('settings.backgroundColor')"
    >
        <ideo-form-input v-model="form.settings.backgroundColor" type="color" name="settings.backgroundColor" v-focus :maxlength="20"></ideo-form-input>
    </ideo-form-group>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import DynamicFormService, { ListItemModel } from '@/modules/cms/dynamic-forms/services/DynamicFormService';
import ModulesService, { FormModel as ModuleModel } from '@/modules/studio/modules/services/ModulesService';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';

defineOptions({
    name: 'widget-form-settings'
});

const { $alert } = useAlerts();
const { $log } = useLogging();

const model = defineModel<any>();
const defaults: any = {
    moduleId: null,
    sectionId: null,
    textColor: null,
    backgroundColor: null
};
const modules = ref<Option<number>[]>([]);
const module = ref<ModuleModel>();
const sections = computed(() => ([
    { value: null, text: '' },
    ...(module.value?.sections.map(p => ({value: p.id, text: p.name})) ?? [])
]));

const form = computed(() => model.value);

onMounted(async () =>
{
    form.value.settings = {...defaults, ...form.value.settings};

    await loadModules();
    await loadModule(form.value.settings.moduleId);
});

watch(() => form.value?.settings, () =>
{
    model.value = form.value;
},
{ deep: true });

watch(() => form.value?.settings.moduleId, async (value, old) =>
{
    if (old)
    {
        form.value.settings.sectionId = null;
    }

    await loadModule(value);
});

async function loadModules()
{
    try
    {
        const pager = new Pager(1, 10000, 'Name', 'ASC');
        const response = await ModulesService.getList({} as any, pager);

        modules.value = [{value: null, text: ''}, ...response.items.map(p => ({
            value: p.id,
            text: p.name
        }))];
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return false;
    }

    return true;
}

async function loadModule(moduleId: number)
{
    if (!moduleId) return;

    try
    {
        const response = await ModulesService.fetch(moduleId);

        module.value = response.result;
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}
</script>
