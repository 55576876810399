<template>
    <ideo-form-group :label="$t('[[[Sortowanie]]]')" :invalid-feedback="form.$errors.first('settings.sortType')" :state="form.$errors.state('settings.sortType')">
        <ideo-form-select v-model="form.settings.sortType" :options="sortTypeOptions" value-field="value" text-field="text" @change="sortTypeChanged" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Ilość wyświetlanych tagów]]]')" :invalid-feedback="form.$errors.first('settings.quantity')" :state="form.$errors.state('settings.quantity')">
        <ideo-form-select name="settings.quantity" v-model="form.settings.quantity" :options="quantity"></ideo-form-select>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';

@Options({
    name: 'widget-tags-cloud-settings'
})
export default class TagsCloudSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        sortType: 'Date',
        quantity: 5
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public get sortTypeOptions(): Option[]
    {
        return [
            { value: 'Date', text: this.$t('[[[Data dodania]]]') },
            { value: 'Alphabetic', text: this.$t('[[[Alfabetycznie]]]') },
            { value: 'Popularity', text: this.$t('[[[Popularność]]]') },
        ];
    }

    public get quantity(): Option<number>[]
    {
        return new Array(10).fill(null).map((value, index) =>
        {
            return { value: index + 1, text: (index + 1).toString() };
        });
    }

    public async sortTypeChanged(): Promise<void>
    {
        this.form.settings = {
            sortType: this.form.settings.sortType
        };
    }
}
</script>
