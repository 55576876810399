import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Resource as File } from '@/modules/core/files/services/StorageService';
import { PublicationEnum, RelatedArticleSectionsEnum } from '@/helpers/Enums';
import { Pagination, Resource, Statement, PagePublication, EntityVersion, MetaSettingsModel } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

/**
 * ArticlesService
 */
export default class ArticlesService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/articles', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param articles number[]
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getListByIds(articles: number[]): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/cms/articles/get-by-ids', {
            params: {
                articles: articles.join(',')
            }
        })).data;
    }

    /**
     * @param articles number[]
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getListByCategoryId(categoryId: number): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`admin/cms/articles/category/${categoryId}`)).data;
    }

    /**
     * @param articles number[]
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getListByAuthorId(authorId: number): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`admin/cms/articles/author/${authorId}`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/cms/articles/${id}`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getRelated(id: number): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`admin/cms/articles/${id}/related`)).data;
    }

    /**
     * @param id number
     * @param boolean showType
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getRelatedFromWidget(id: number, showType: boolean = false): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`admin/cms/articles/${id}/relatedFromWidget`, {
            params: {
                showType: showType
            }
        })).data;
    }

    /**
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/cms/articles`, model)).data;
    }

    /**
     * @param id number
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async update(id: number, model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/cms/articles/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/articles/${id}`)).data as Statement;
    }

    /**
     * @param id number
     * @param status PublicationEnum
     *
     * @returns Promise<Statement>
     */
    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/cms/articles/${id}/status`, {
            status: status
        })).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async publish(id: number, model: PublishModel): Promise<Statement>
    {
        return (await axios.patch(`admin/cms/articles/${id}/publish`, model)).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async attach(id: number, pageId: number): Promise<Statement>
    {
        return (await axios.post(`admin/cms/articles/${id}/page/${pageId}`)).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async detach(id: number, pageId: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/articles/${id}/page/${pageId}`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Pagination<EntityVersion>>
     */
    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/cms/articles/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<PagePublication[]>
     */
    public static async getPagePublications(id: number): Promise<PagePublication[]>
    {
        return (await axios.get<PagePublication[]>(`admin/cms/articles/${id}/pages`)).data;
    }

    /**
     * @param search string
     * @param limit number
     * @param pageId number
     * @param articleId number
     * @param boolean showType
     * @param boolean onlyOpinions
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string,
        limit: number = 10,
        pageId: number = 0,
        articleId: number = 0,
        showType: boolean = false,
        onlyOpinions: boolean = false): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/cms/articles/autocomplete', {
            params: {
                search: search,
                limit: limit,
                pageId: pageId,
                articleId: articleId,
                showType: showType,
                onlyOpinions: onlyOpinions
            }
        })).data;
    }

    /**
     * @param id number
     * @param boolean showType
     * @param boolean onlyOpinions
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModel(id: number, showType: boolean = false, onlyOpinions: boolean = false): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/articles/autocomplete/${id}`, {
            params: {
                showType: showType,
                onlyOpinions: onlyOpinions
            }
        })).data;
    }

    /**
     * @param originId number
     * @param boolean showType
     * @param boolean onlyOpinions
     *
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModelByOriginId(originId: number, showType: boolean = false, onlyOpinions: boolean = false): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/articles/autocomplete/${originId}/origin`, {
            params: {
                showType: showType,
                onlyOpinions: onlyOpinions
            }
        })).data;
    }
}

export interface ListItemModel
{
    authors: string;
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    status: PublicationEnum;
    originId: number;
    isArchive: boolean;
    labelName: string;
    labelTextColor: string;
    labelBackgroundColor: string;
}

export interface FilterModel
{
    authorId?: number;
    pageId?: number;
    userId: number;
    status: PublicationEnum;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    onlyMine: boolean;
    onlyTeam: boolean;
    isOpinion: boolean;
    isSponsored: boolean;
    isPremium: boolean;
    contentOnly: boolean;
    shareDisabled: boolean;
    commentsDisabled: boolean;
    fullPageView: boolean;
    isOpenPremium: boolean;
}

export interface UpsertModel
{
    id: number;
    originId: number;
    title: string;
    shortTitle: string;
    tags: string[];
    lead: string;
    content: string[];
    picture?: File;
    attachments: File[];
    contentOnly: boolean;
    meta: MetaSettingsModel | null;
    photoGallery:File[];
    video?: File;
    videoCover?: File;
    videoUrl?: string;
    relatedArticles: RelatedModel[];
    authors: number[];
    preTitle: string;
    surveyId: number;
    isSurveyOn: boolean;
    isPremium: boolean;
    isOpenPremium: boolean;
    premiumAttachments: File[];
    premiumPhotoGallery: File[];
    premiumVideo?: File;
    premiumVideoCover?: File;
    premiumVideoUrl?: string;
    isOpinion: boolean;
    isSponsored: boolean;
    commentsDisabled: boolean;
    shareDisabled: boolean;
    labelId?: number;
    categories: string[];
    fullPageView: boolean;
    isContactFormOn: boolean;
    contactFormCategoryId?: number;
    contactFormId?: number;
    articleSource: string;
    priority: boolean;
}

export interface FormModel extends UpsertModel
{
    datePublishedUtc?: DateTime;
    dateDismissedUtc?: DateTime;
    status: PublicationEnum;
}

export interface PublishModel
{
    datePublishedUtc?: DateTime;
    dateDismissedUtc?: DateTime;
}

export interface RelatedModel
{
    id: number;
    section: RelatedArticleSectionsEnum | number;
}
