<template>
    <div class="row">
        <div class="col-6">
            <ideo-form-group :label="$t('[[[Publikuj od]]]')" :invalid-feedback="form.$errors.first(`settings.datePublishedUtc`)" :state="form.$errors.state(`settings.datePublishedUtc`)" required>
                <ideo-datetime type="datetime" v-model="form.settings.datePublishedUtc" name="datePublishedUtc" />
            </ideo-form-group>
        </div>
        <div class="col-6">
            <ideo-form-group :label="$t('[[[Publikuj do]]]')" :invalid-feedback="form.$errors.first(`settings.dateDismissedUtc`)" :state="form.$errors.state(`settings.dateDismissedUtc`)">
                <ideo-datetime type="datetime" v-model="form.settings.dateDismissedUtc" name="dateDismissedUtc" />
            </ideo-form-group>
        </div>
    </div>

    <ideo-form-group :label="$t('[[[Typ bannera]]]')" :invalid-feedback="form.$errors.first('settings.type')" :state="form.$errors.state('settings.type')">
        <ideo-form-select v-model="form.settings.type" :options="typeOptions" value-field="value" text-field="text" @change="typeChanged" />
    </ideo-form-group>

    <ideo-form-group :label="$t('[[[Kod bannera (tutaj wklej kod HTML i JS)]]]')" :invalid-feedback="form.$errors.first('settings.content')" :state="form.$errors.state('settings.content')" v-if="form.settings.type == 'text'">
        <ideo-form-textarea v-model="form.settings.scriptText" name="scriptText" rows="10"></ideo-form-textarea>
    </ideo-form-group>

    <div v-if="form.settings.type == 'image'">
        <ideo-form-group :label="$t('[[[Odnośnik URL]]]')" :invalid-feedback="form.$errors.first('settings.url')" :state="form.$errors.state('settings.url')">
            <ideo-form-input type="text" v-model="form.settings.url" name="url"></ideo-form-input>
        </ideo-form-group>

        <ideo-form-group :invalid-feedback="form.$errors.first('settings.openNewTab')" :state="form.$errors.state('settings.openNewTab')" :nospace="true">
            <ideo-form-checkbox v-model="form.settings.openNewTab" switch>{{ $t('[[[Otwieraj w nowym oknie]]]') }}</ideo-form-checkbox>
        </ideo-form-group>

        <ideo-form-group :label="$t('[[[Plik graficzny]]]')" :invalid-feedback="form.$errors.first(`settings.image`)" :state="form.$errors.state(`settings.image`)">
            <filepicker v-model="form.settings.image" media="images" />
        </ideo-form-group>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Option } from '@/helpers/Interfaces';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import { DateTime } from 'luxon';

@Options({
    name: 'widget-banner-settings'
})
export default class BannerSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        datePublishedUtc: DateTime.now(),
        type: 'text',
        scriptText: '',
        url: '',
        openNewTab: false,
        image: null
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public get typeOptions(): Option[]
    {
        return [
            { value: 'text', text: this.$t('[[[Tekstowy / HTML / JS]]]') },
            { value: 'image', text: this.$t('[[[Obraz]]]') },
        ];
    }

    public async typeChanged(): Promise<void>
    {
        this.form.settings = {
            datePublishedUtc: DateTime.now(),
            type: this.form.settings.type,
            scriptText: '',
            url: '',
            openNewTab: false,
            image: null
        };
    }
}
</script>