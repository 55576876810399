<template>
    <div class="row">
        <div class="col-12">
            <ideo-form-group :label="$t('[[[Ikona]]]')" :invalid-feedback="form.$errors.first('settings.file')" :state="form.$errors.state('settings.file')" required>
                <filepicker name="settings.file" v-model="form.settings.file" media="images" />
            </ideo-form-group>
        </div>
        <div class="col-12">
            <ideo-form-group :label="$t('[[[Typ linku]]]')" :invalid-feedback="form.$errors.first('mode')" :state="form.$errors.state('mode')" required>
                <ideo-form-radio-group name="mode" v-model="form.settings.mode" :options="pageModeOptions" stacked></ideo-form-radio-group>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Wybierz katalog]]]')" :invalid-feedback="form.$errors.first('settings.folderId')" :state="form.$errors.state('settings.folderId')" v-if="form.settings.mode == pageMode.Alias" required>
                <ideo-form-select name="settings.folderId" v-model="form.settings.folderId" :options="folders" value-field="id" text-field="name" />
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Link do strony]]]')" :invalid-feedback="form.$errors.first('settings.aliasId')" :state="form.$errors.state('settings.aliasId')" v-if="form.settings.mode == pageMode.Alias" required>
                <ideo-form-select name="settings.aliasId" v-model="form.settings.aliasId" :options="pages" value-field="id" text-field="option" />
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Adres strony]]]')" :invalid-feedback="form.$errors.first('settings.externalUrl')" :state="form.$errors.state('settings.externalUrl')" v-if="form.settings.mode == pageMode.Link" required>
                <ideo-form-input type="text" name="settings.externalUrl" v-model="form.settings.externalUrl"></ideo-form-input>
            </ideo-form-group>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import { PageModeEnum } from '@/helpers/Enums';
import { $t } from '@/plugins/localization';
import PagesService, { FolderListItemModel, PageListItemModel } from '@/modules/cms/pages/services/PagesService';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-icon-settings'
})
export default class IconSettings extends Vue
{
    @Prop() public modelValue!: any;
    public aliasFolderId?: number = null;
    public folders: FolderListItemModel[] = [];
    public pages: PageListItemModel[] = [];

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public defaults: any = {
        file: null,
        mode: PageModeEnum.Alias,
        folderId: 0,
        aliasId: null,
        externalUrl: ''
    };

    public get pageModeOptions(): Option[]
    {
        return [
            { value: PageModeEnum.Alias, text: $t('[[[Link do wybranej strony serwisu]]]') },
            { value: PageModeEnum.Link, text: $t('[[[Link do zewnętrznej strony]]]') }
        ];
    }

    public get pageMode(): typeof PageModeEnum
    {
        return PageModeEnum;
    }

    public async loadFolders(): Promise<void>
    {
        try
        {
            const response = (await PagesService.getFolders(this.form.websiteId, this.form.locale)).items.map(p => p.result);

            this.folders = [{
                id: 0,
                name: this.$t('[[[Wybierz katalog]]]'),
                locale: ''
            }].concat(response);
        }
        catch (ex)
        {
            this.folders = [];
            this.$log.debug(ex);
        }
    }

    public async created(): Promise<void>
    {
        await this.loadFolders();
        await this.loadPages();
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    @Watch('form.settings.folderId')
    public async loadPages(): Promise<void>
    {
        this.form.wait();

        try
        {
            const response = await PagesService.getPagesWithoutElementsByFolder(this.form.settings.folderId);

            this.pages = [{
                id: null as number,
                name: '',
                option: this.$t('[[[Wybierz stronę]]]'),
                folderId: 0,
                module: '',
                pageMode: this.pageMode.Page,
                level: 0,
                hasChildren: false,
                position: 0
            }].concat(response);
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.form.$errors.push('settings.aliasId', this.$t('[[[Nie udało się pobrać listy stron]]]'))
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public async validate(): Promise<boolean>
    {
        if (this.form.settings.mode == PageModeEnum.Alias)
        {
            this.form.validate(schema => ({
                settings: schema.object({
                    folderId: schema.string().nullable().required(this.$t('[[[Wybranie katalogu jest wymagane]]]')),
                    aliasId: schema.string().nullable().required(this.$t('[[[Wybranie strony jest wymagane]]]'))
                })
            }));
        }
        else
        {
            this.form.validate(schema => ({
                settings: schema.object({
                    externalUrl: schema.string().nullable().required(this.$t('[[[[Zewnętrzny link jest wymagany]]]')),
                })
            }));
        }

        if (this.form.settings.file === null)
        {
            const errors: Record<string, string[]> = {};

            errors['settings.file'] = [this.$t('[[[Wybranie ikony jest wymagane.]]]')];
            this.form.$errors.record(errors);

            return false;
        }
    }
}
</script>
