<template>
    <div class="alert alert-light pb-1">
        <ideo-form-group :label="$t('[[[Działanie modułu w oparciu o]]]')">
            <ideo-form-radio-group stacked>
                <ideo-form-radio name="mode" v-model="form.settings.mode" value="form">{{ $t('[[[Formularz dynamiczny]]]') }}</ideo-form-radio>
                <ideo-form-radio name="mode" v-model="form.settings.mode" value="module">{{ $t('[[[Moduł dynamiczny]]]') }}</ideo-form-radio>
            </ideo-form-radio-group>
        </ideo-form-group>
        <template v-if="form.settings.mode == 'form'">
            <ideo-form-group :label="$t('[[[Wybierz formularz]]]')" required>
                <div class="d-flex flex-column flex-md-row">
                    <div class="flex-grow-1">
                        <ideo-autocomplete
                            v-model="form.settings.dynamicFormOriginId"
                            name="dynamicFormOriginId"
                            track-by="originId"
                            :placeholder="$t('[[[Wyszukaj formularz...]]]')"
                            :fetch="(id: number) => fetchForm(id)"
                            :search="(query: string, limit: number) => searchForm(query, limit)"
                        >
                            <template #selected="{ option }">
                                <div class="text-wrap">
                                    {{ option.title }}
                                </div>
                            </template>
                            <template #option="{ option }">
                                <div class="text-wrap">
                                    {{ option.title }}
                                </div>
                            </template>
                        </ideo-autocomplete>
                    </div>
                </div>
            </ideo-form-group>
        </template>
        <template v-if="form.settings.mode == 'module'">
            <ideo-form-group :label="$t('[[[Moduł dynamiczny]]]')" :invalid-feedback="form.$errors.first('moduleId')" :state="form.$errors.state('moduleId')" required>
                <ideo-form-select v-model="form.settings.moduleId" type="text" name="moduleId" :options="modules"></ideo-form-select>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Sekcja modułu]]]')" :invalid-feedback="form.$errors.first('sectionId')" :state="form.$errors.state('sectionId')" required>
                <ideo-form-select v-model="form.settings.sectionId" type="text" name="sectionId" :options="sections" :disabled="!form.settings.moduleId"></ideo-form-select>
            </ideo-form-group>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import DynamicFormService, { ListItemModel } from '@/modules/cms/dynamic-forms/services/DynamicFormService';
import ModulesService, { FormModel as ModuleModel } from '@/modules/studio/modules/services/ModulesService';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { useLocalization } from '@/plugins/localization';

defineOptions({
    name: 'module-dynamic-forms-settings'
});

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $i18n } = useLocalization();

const model = defineModel<any>();
const defaults: any = {
    mode: 'form',
    dynamicFormOriginId: null,
    moduleId: null,
    sectionId: null
};
const modules = ref<Option<number>[]>([]);
const module = ref<ModuleModel>();
const sections = computed(() => ([
    { value: null, text: '' },
    ...(module.value?.sections.map(p => ({value: p.id, text: p.name})) ?? [])
]));

const form = computed(() => model.value);

onMounted(async () =>
{
    form.value.settings = {...defaults, ...form.value.settings};

    await loadModules();
    await loadModule(form.value.settings.moduleId);
});

watch(() => form.value?.settings, () =>
{
    model.value = form.value;
},
{ deep: true });

watch(() => form.value?.settings.moduleId, async (value, old) =>
{
    if (old)
    {
        form.value.settings.sectionId = null;
    }

    await loadModule(value);
});

async function fetchForm(id: number): Promise<ListItemModel>
{
    const response = await DynamicFormService.fetchAutocompleteModel(id, $i18n.locale());

    return response || null;
}

async function searchForm(query: string, limit: number): Promise<ListItemModel[]>
{
    return await DynamicFormService.getAutocompleteList(query, limit, $i18n.locale());
}

async function loadModules()
{
    try
    {
        const pager = new Pager(1, 10000, 'Name', 'ASC');
        const response = await ModulesService.getList({} as any, pager);

        modules.value = [{value: null, text: ''}, ...response.items.map(p => ({
            value: p.id,
            text: p.name
        }))];
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return false;
    }

    return true;
}

async function loadModule(moduleId: number)
{
    if (!moduleId) return;

    try
    {
        const response = await ModulesService.fetch(moduleId);

        module.value = response.result;
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}
</script>
