<template>
    <ideo-form-group :invalid-feedback="form.$errors.first('settings.publishByUser')" :state="form.$errors.state('settings.publishByUser')">
        <ideo-form-checkbox v-model="form.settings.publishByUser">{{ $t('[[[Publikacja galerii przez użytkownika]]]') }}</ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :invalid-feedback="form.$errors.first('settings.autoPublish')" :state="form.$errors.state('settings.autoPublish')" v-if="form.settings.publishByUser">
        <ideo-form-checkbox v-model="form.settings.autoPublish">{{ $t('[[[Automatyczna publikacja galerii]]]') }}</ideo-form-checkbox>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';

@Options({
    name: 'module-videos-settings'
})
export default class VideosSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        publishByUser: false,
        autoPublish: false
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Watch('form.settings.publishByUser', { deep: true })
    public onSettingsChangedd(value: any): void
    {
        if (!value)
        {
            this.form.settings.autoPublish = false;
        }
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
