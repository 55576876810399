<script setup lang="ts">
import { ref, computed, provide, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import { createPopper as createPopperInstance, Instance as Popper } from '@popperjs/core';
import { onClickOutside } from '@vueuse/core';
import { normalizeClasses } from '@/helpers/Utils';

const instance = getCurrentInstance();

const VARIANTS = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link', 'none'];

type Variant = typeof VARIANTS[number];

defineOptions({
    name: 'ideo-dropdown'
});

const popper = ref<Popper>(null);
const visible = ref(false);
const dropdown = ref<any>(null);
const toggle = ref<any>(null);
const menu = ref<any>(null);

const props = defineProps({
  "block": { type: Boolean, default: false },
  "disabled": { type: Boolean, default: false },
  "boundary": { default: null },
  "dropleft": { type: Boolean, default: false },
  "dropright": { type: Boolean, default: false },
  "dropup": { type: Boolean, default: false },
  "self": { type: Boolean,  },
  "menuClass": { default: () => ({}) },
  "noCaret": { type: Boolean, default: false },
  "noFlip": { type: Boolean, default: false },
  "right": { type: Boolean, default: false },
  "size": { default: 'md' },
  "split": { type: Boolean, default: false },
  "splitButtonType": { default: 'button' },
  "splitClass": { default: () => ({}) },
  "splitVariant": { default: null },
  "text": { default: 'Toggle dropdown' },
  "toggleClass": { default: () => ({}) },
  "variant": { default: 'secondary' },
  "noIcon": { type: Boolean, default: false }
});

const emit = defineEmits(["show", "hide", "shown", "hidden", "click", "toggle"]);

const showClass = computed<Record<string, boolean>>(() => ({
    'show': visible.value
}));

const positionClass = computed<Record<string, boolean>>(() => ({
    'position-static': props.boundary !== null
}));

const flag = (value: any): boolean =>
{
    return value !== false;
};

const containerClasses = computed<Record<string, boolean>>(() => ({
    'dropdown-container': true,
    'dropdown-self': props.self,
    'btn-group': true,
    'w-100': flag(props.block),
    ...positionClass.value
}));

const directionClasses = computed<Record<string, boolean>>(() => ({
    'dropup': flag(props.dropup),
    'dropright': flag(props.dropright),
    'dropleft': flag(props.dropleft)
}));


const dropdownClasses = computed<Record<string, boolean>>(() => ({
    'dropdown': true,
    'btn-group': true,
    'd-flex w-100': flag(props.block),
    'no-icon': flag(props.noIcon),
    ...positionClass.value,
    ...directionClasses.value,
    ...showClass.value
}));

const sizeClasses = computed<Record<string, boolean>>(() => ({
    'btn-sm': props.size == 'sm',
    'btn-lg': props.size == 'lg'
}));


const splitClasses = computed<Record<string, boolean>>(() =>
{
    const variant = props.splitVariant || props.variant;
    const btnClasses = `btn btn-${variant}`;

    return {
        [btnClasses]: true,
        'dropdown-btn': true,
        'btn-block': flag(props.block) && flag(props.split),
        ...sizeClasses.value,
        ...normalizeClasses(props.splitClass)
    };
});

const toggleClasses = computed<Record<string, boolean>>(() =>
{
    const btnClasses = `btn btn-${props.variant}`;

    return {
        [btnClasses]: true,
        'btn-block': flag(props.block) && !flag(props.split),
        'dropdown-btn': true,
        'dropdown-toggle': true,
        'dropdown-toggle-split': flag(props.split),
        'dropdown-toggle-no-caret': flag(props.noCaret),
        ...sizeClasses.value,
        ...normalizeClasses(props.toggleClass)
    };
});

const menuClasses = computed<Record<string, boolean>>(() => ({
    'dropdown-menu': true,
    'dropdown-menu-right': props.right,
    ...normalizeClasses(props.menuClass),
    ...showClass.value
}));

const triggerShow = (): void =>
{
    emit('show');
};

const triggerHide = (): void =>
{
    emit('hide');
};

const triggerBeforeEvents = (): void =>
{
    if (visible.value == false)
        triggerShow();
    else
        triggerHide();
};

const getPopperConfig = (): any =>
{
    let placement = 'bottom-start';

    if (flag(props.dropup))
    {
        placement = flag(props.right) ? 'top-end' : 'top-start';
    }
    else if (flag(props.dropright))
    {
        placement = 'right-start';
    }
    else if (flag(props.dropleft))
    {
        placement = 'left-start';
    }
    else if (flag(props.right))
    {
        placement = 'bottom-end';
    }

    const config: any = {
        placement,
        modifiers: [
            {
                name: 'flip',
                enabled: !flag(props.noFlip),
                options: {
                    padding: 0
                }
            }
        ]
    };

    if (props.boundary)
    {
        config.modifiers.push({
            name: 'preventOverflow',
            options: {
                boundary: document.querySelector(props.boundary),
                padding: 0
            }
        });
    }

    return config;
};

const destroyPopper = (): void =>
{
    popper.value && popper.value.destroy();
    popper.value = null;
};

const createPopper = (element: HTMLElement): void =>
{
    destroyPopper();

    popper.value = createPopperInstance(element, menu.value, getPopperConfig());
};

const triggerShown = (): void =>
{
    const el = (flag(props.dropup) && flag(props.right)) || flag(props.split) ? instance.vnode.el : toggle.value;

    createPopper(el);
};

const triggerHidden = (): void =>
{
    emit('hidden');
    destroyPopper();
};

const triggerClick = (e: Event): void =>
{
    emit('click', e);
};

const triggerToggle = (): void =>
{
    emit('toggle', visible.value);
};

const clickSplit = (e: Event): void =>
{
    if (props.disabled == false)
        triggerClick(e);
};

const toggleMenu = (): void =>
{
    visible.value ? hide() : show();
};

const triggerAfterEvents = (): void =>
{
    if (visible.value == true)
        triggerShown();
    else
        triggerHidden();
};

const show = (): void =>
{
    if (props.disabled == false && visible.value == false)
    {
        triggerBeforeEvents();
        visible.value = true;
        triggerAfterEvents();
        triggerToggle();
    }
};

const hide = (): void =>
{
    if (props.disabled == false && visible.value == true)
    {
        triggerBeforeEvents();
        visible.value = false;
        triggerAfterEvents();
        triggerToggle();
    }
};

provide('hideDropdown', hide);

onMounted(() =>
{
    onClickOutside(dropdown.value, () =>
    {
        hide();
    },
    { ignore: ['.modal'] });
});

onBeforeUnmount(() =>
{
    destroyPopper();
});

defineExpose({
    show,
    hide,
    toggle
});
</script>

<template>
    <div :class="containerClasses" ref="dropdown">
        <div :class="dropdownClasses">
            <button :type="splitButtonType" :class="splitClasses" :disabled="disabled" ref="split" @click.stop="clickSplit" v-if="flag(split) && !flag(dropleft)">
                <slot name="button-content">
                    {{ text }}
                </slot>
            </button>
            <button type="button" :class="toggleClasses" :disabled="disabled" ref="toggle" @click.prevent.stop="toggleMenu">
                <slot name="button-content" v-if="split === false">
                    {{ text }}
                </slot>
            </button>
            <div :class="menuClasses" ref="menu">
                <slot name="default"></slot>
            </div>
        </div>
        <button :type="splitButtonType" :class="splitClasses" :disabled="disabled" ref="split" @click.stop="clickSplit" v-if="flag(split) && flag(dropleft)">
            <slot name="button-content">
                {{ text }}
            </slot>
        </button>
    </div>
</template>

<style lang="scss">
.dropdown {
    &-self {
        .dropdown-toggle {
            outline: none;
            border: none;
        }
    }

    & &-menu {
        margin: 0 !important;
        padding: 2px 0;
    }

    & &-item {
        min-height: 36px;
        display: flex;
        align-items: center;

        .icon, [class*=fa-] {
            min-width: 20px;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    & &-divider {
        margin: 2px 0;
    }

    & &-toggle {
        &::before,
        &::after {
            vertical-align: middle;
        }

        &-no-caret {
            &::before,
            &::after {
                display: none !important;
            }
        }
    }

    &.no-icon &-btn:after {
        display: none;
    }
}
</style>
