<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { TreeNodeCoursesProgram } from '../utils';
import { ModulesKey } from '@/helpers/Enums';

const route = useRoute();
const { $events } = useEvents();
const { $alert } = useAlerts();
const { $t } = useLocalization();

defineOptions({
    name: 'ideo-tree-courses-program-node'
});

const props = defineProps({
  "node": null,
  "pages": null,
  "expanded": null,
  "menu": { type: [null, Function],  },
  "move": { type: Function,  },
  "iconVisible": { type: Boolean,  }
});

const emit = defineEmits(["toggle", "click", "delete", "resetUnit", "sort"]);

const hover = ref(false);
const contextMenu = ref(false);
const menuItems = ref([]);
const reload = ref(0);

const hasChildren = computed(() => props.node.hasChildren);
const hasMenu = computed(() => props.menu != null);
const isExpanded = computed(() => hasChildren.value && props.expanded.indexOf(props.node.id) >= 0);
const isCollapsed = computed(() => hasChildren.value && props.expanded.indexOf(props.node.id) === -1);

const isActive = computed(() =>
{
    return route.params.id === props.node.id.toString();
});

const container = computed(() => `node-container-${props.node.id}`);

function menuItemId(index: number)
{
    return `dropdown-${props.node.id}-item-${index}`;
}

const hidePopover = (node: TreeNodeCoursesProgram): void =>
{
    if (node === null || node != props.node)
    {
        menuItems.value = [];
        contextMenu.value = false;
        hover.value = false;
    }
};

onMounted(async () =>
{
    $events.$on('tree::popover::hide', hidePopover);
    menuItems.value = await getMenu(props.node);
});

onBeforeUnmount(() =>
{
    $events.$off('tree::popover::hide', hidePopover);
});

const getMenu = async (node: TreeNodeCoursesProgram): Promise<any[]> =>
{
    if (props.menu)
        return await props.menu(node);
    else
        return [];
};

const icon = (icon: string): string =>
{
    let icons = icon;

    if (props.node.isActive)
    {
        icons += ' text-success';
    }

    return props.node.icon ? props.node.icon : icons;
};

const mouseOver = (): void =>
{
    hover.value = true;
};

const mouseOut = (): void =>
{
    if (contextMenu.value == false)
    {
        hover.value = false;
    }
};

const togglePopover = async (): Promise<void> =>
{
    if (contextMenu.value == false)
    {
        $events.$emit('tree::popover::hide', props.node);
    }

    contextMenu.value = !contextMenu.value;

    if (contextMenu.value)
    {
        menuItems.value = await getMenu(props.node);

        if (menuItems.value.length === 0)
        {
            $alert.info($t('[[[Ten element nie zawiera menu kontekstowego.]]]'));
            contextMenu.value = false;
        }
    }
};

const onMenuItem = (node: TreeNodeCoursesProgram, item: any, confirmed: boolean) =>
{
    if (confirmed)
    {
        $events.$emit('tree::popover::hide', node);
        togglePopover();
        item.click(node);
        onDelete(node);
    }

    reload.value++;
};

const onMenuItemIcon = (node: TreeNodeCoursesProgram, item: any, confirmed: boolean): void =>
{
    if (confirmed)
    {
        item.click(node);
    }
};

const onToggle = (node: TreeNodeCoursesProgram): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNodeCoursesProgram): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNodeCoursesProgram[]): void =>
{
    emit('sort', pages);
};

const resetUnitResult = (node: TreeNodeCoursesProgram): void =>
{
    emit('resetUnit', node);
};

const onDelete = (node: TreeNodeCoursesProgram): void =>
{
    $events.$emit('tree::popover::hide', node);
    emit('delete', node);
};
</script>

<template>
    <li :id="container" :class="[{'active': isActive}, { 'white' : node.module === ModulesKey.TOPICS }]" @mouseover.stop="mouseOver" @mouseout.stop="mouseOut" :key="reload">
        <span class="d-flex align-items-center course-item">
            <i class="fa fa-fw spaces" v-if="!hasChildren"></i>
            <span v-if="isCollapsed" @click.prevent="onToggle(node)" class="box">
                <i class="fal fa-fw fa-plus"></i>
            </span>
            <span v-if="isExpanded" @click.prevent="onToggle(node)" class="box">
                <i class="fal fa-fw fa-minus"></i>
            </span>
            <i :class="icon('fad fa-fw fa-file fa-swap')" v-if="!hasChildren"></i>
            <i :class="icon('fa fa-fw fa-folder')" v-if="isCollapsed"></i>
            <i :class="icon('fa fa-fw fa-folder-open fa-swap-opacity')" v-if="isExpanded"></i>
            <a href="#" @click.stop.prevent="onClick(node)" class="flex-fill course-element" :class="{'text-dark': isActive}">{{ node.name }}</a>
            <span class="d-flex justify-content-end column">
                <span class="d-flex justify-content-center align-items-center" :style="{'width': '140px'}">
                    <i v-if="node.module != ModulesKey.TOPICS" :class="['fa fa-fw fa-circle', node.isRequired ? 'text-success' : 'text-default']" :title="node.isRequired ? $t('[[[element wymagany]]]') : $t('[[[element niewymagany]]]')"></i>
                    <template v-else>
                        {{ $t('[[[wymagany]]]') }}
                    </template>
                </span>
                <span v-if="hasMenu">
                    <template v-for="(item, index) in menuItems" :key="index">
                        <span v-show="item.name" :id="menuItemId(index)" @click.prevent="onMenuItemIcon(node, item, !item.confirm)" :title="item.name">
                            <i class="me-2 icon" :class="[item.icon, item.class]"></i>
                            <confirmation v-if="item.confirm" :target="menuItemId(index)" :message="item.confirm" :value="item" @confirm="onMenuItem(node, item, true)" />
                        </span>
                    </template>
                </span>
            </span>
        </span>
        <ideo-tree-courses-program-nodes :pages="pages" :expanded="expanded" :icon-visible="iconVisible" :parent="node" :menu="menu" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" @resetUnit="resetUnitResult(node)" class="tree tree-nested" />
    </li>
</template>

<style scoped lang="scss">
.text-default {
    color: #cdd1d9 !important;
}

.box {
    background-color: #E1E2E3;
    border-radius: 4px;
    padding: 4px 5px;
    cursor: pointer;

    & > i {
        font-size: .75rem;
        color: #646464;
        display: block;
    }
}

.spaces {
    padding: 4px 5px;
    display: block;
    min-width: 26px;
}

.course-item {
    gap: 10px;
    padding: 10px 15px;
}

.tree li > span a.course-element {
    font-size: .875rem;
    color: var(--color-text);
    font-weight: 400;
}

.tree:not(.tree-nested) li {
    margin: 0;

    ul.tree-nested {
        padding: 0;

        span.course-item {
            padding-left: 36px;
        }
    }

    .course-item {
        background: var(--course-program);
        border-bottom: 1px solid #ECECEC;
    }
}

.tree:not(.tree-nested) li.white {

    & > .course-item {
        padding: 20px 15px;
        .course-element {
            font-weight: 600;
        }
    }

    .course-item {
        background: var(--color-white);
    }
}

.tree.tree-nested li:hover {
    background: var(--color-header);

    .course-item {
        background: var(--color-header);
    }
}

@media screen and (max-width: 960px) {
    .column {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
}
</style>
