/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'sitemap',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Strony
            {
                path: 'pages',
                name: 'cms-pages',
                meta: { auth: true },
                component: () => import('@/modules/cms/pages/views/PagesList.vue'),
                children: [
                    {
                        path: 'add/:folderId',
                        props: true,
                        name: 'cms-pages-add',
                        meta: { auth: true },
                        component: () => import('@/modules/cms/pages/views/PageForm.vue')
                    },
                    {
                        path: ':id',
                        props: true,
                        name: 'cms-pages-edit',
                        meta: { auth: true, model: "Ideo.Core.Data.Model.CmsPage" },
                        component: () => import('@/modules/cms/pages/views/PageForm.vue')
                    }
                ]
            },
            // Aranżacje
            {
                path: 'layouts',
                name: 'cms-layouts',
                meta: { auth: true },
                component: () => import('@/modules/cms/layouts/views/LayoutsList.vue')
            },
            {
                path: 'layouts/:id',
                name: 'cms-layouts-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/layouts/views/LayoutForm.vue')
            },
            {
                path: 'layouts/add',
                name: 'cms-layouts-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/layouts/views/LayoutForm.vue')
            },
            // Witryny
            {
                path: 'websites',
                name: 'cms-websites',
                meta: { auth: true },
                component: () => import('@/modules/cms/websites/views/WebsitesList.vue')
            },
            {
                path: 'websites/add',
                name: 'cms-websites-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/websites/views/WebsiteForm.vue')
            },
            {
                path: 'websites/:id',
                name: 'cms-websites-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/websites/views/WebsiteForm.vue')
            },
            // Szablony
            {
                path: 'themes',
                name: 'cms-themes',
                meta: { auth: true },
                component: () => import('@/modules/cms/themes/views/ThemesList.vue')
            },
            {
                path: 'themes/add',
                name: 'cms-themes-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/themes/views/ThemeForm.vue')
            },
            {
                path: 'themes/:id',
                name: 'cms-themes-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/themes/views/ThemeForm.vue')
            }
        ]
    },
    {
        // Moduły
        path: 'modules',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Artykuły
            {
                path: 'articles',
                name: 'cms-articles',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/ArticlesList.vue')
            },
            {
                path: 'articles/add',
                name: 'cms-articles-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/ArticleForm.vue')
            },
            {
                path: 'articles/:id',
                name: 'cms-articles-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.CmsArticle" },
                component: () => import('@/modules/cms/articles/views/ArticleForm.vue')
            },
            // Kategorie artykułów
            {
                path: 'articles/categories',
                name: 'cms-articles-categories',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/categories/CategoriesList.vue')
            },
            {
                path: 'articles/categories/sort',
                name: 'cms-articles-categories-sort',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/categories/CategoriesSort.vue')
            },
            {
                path: 'articles/categories/:id',
                name: 'cms-articles-categories-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/categories/CategoryForm.vue')
            },
            {
                path: 'articles/categories/add',
                name: 'cms-articles-categories-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/categories/CategoryForm.vue')
            },
            // Etykiety
            {
                path: 'articles/labels',
                name: 'cms-articles-labels',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/labels/LabelsList.vue')
            },
            {
                path: 'articles/labels/:id',
                name: 'cms-articles-labels-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/labels/LabelForm.vue')
            },
            {
                path: 'articles/labels/add',
                name: 'cms-articles-labels-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/articles/views/labels/LabelForm.vue')
            },
            // Czołówka
            // {
            //     path: 'articles/headline-covers',
            //     name: 'cms-articles-headline-covers',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/articles/views/headline-covers/HeadlineCoversList.vue')
            // },
            // {
            //     path: 'articles/headline-covers/:id',
            //     name: 'cms-articles-headline-covers-edit',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/articles/views/headline-covers/HeadlineCoverForm.vue')
            // },
            // {
            //     path: 'articles/headline-covers/add',
            //     name: 'cms-articles-headline-covers-add',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/articles/views/headline-covers/HeadlineCoverForm.vue')
            // },
            // Aktualności
            {
                path: 'informations',
                name: 'cms-informations',
                meta: { auth: true },
                component: () => import('@/modules/cms/informations/views/InformationsList.vue')
            },
            {
                path: 'informations/add',
                name: 'cms-informations-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/informations/views/InformationForm.vue')
            },
            {
                path: 'informations/:id',
                name: 'cms-informations-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.CmsInformation" },
                component: () => import('@/modules/cms/informations/views/InformationForm.vue')
            },
            // Faq
            {
                path: 'faq',
                name: 'cms-faq',
                meta: { auth: true },
                component: () => import('@/modules/cms/faq/views/FaqList.vue')
            },
            {
                path: 'faq/add',
                name: 'cms-faq-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/faq/views/FaqForm.vue')
            },
            {
                path: 'faq/:id',
                name: 'cms-faq-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.CmsFaq" },
                component: () => import('@/modules/cms/faq/views/FaqForm.vue')
            },
            // Ankiety
            {
                path: 'surveys',
                name: 'cms-surveys',
                meta: { auth: true },
                component: () => import('@/modules/cms/surveys/views/SurveysList.vue')
            },
            {
                path: 'surveys/add',
                name: 'cms-surveys-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/surveys/views/SurveysForm.vue')
            },
            {
                path: 'surveys/:id',
                name: 'cms-surveys-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/surveys/views/SurveysForm.vue')
            },
            // Tagi
            {
                path: 'tags',
                name: 'cms-tags',
                meta: { auth: true },
                component: () => import('@/modules/cms/tags/views/TagsList.vue')
            },
            // Kategorie linków
            {
                path: 'link/categories',
                name: 'cms-link-categories',
                meta: { auth: true },
                component: () => import('@/modules/cms/link/views/categories/CategoriesList.vue')
            },
            {
                path: 'link/categories/:id',
                name: 'cms-link-categories-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/link/views/categories/CategoryForm.vue')
            },
            {
                path: 'link/categories/add',
                name: 'cms-link-categories-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/link/views/categories/CategoryForm.vue')
            },
            // Linki
            {
                path: 'link/links',
                name: 'cms-link-links',
                meta: { auth: true },
                component: () => import('@/modules/cms/link/views/links/LinksList.vue')
            },
            {
                path: 'link/links/add',
                name: 'cms-link-links-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/link/views/links/LinkForm.vue')
            },
            {
                path: 'link/links/:id',
                name: 'cms-link-links-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.CmsLink" },
                component: () => import('@/modules/cms/link/views/links/LinkForm.vue')
            },
            // Autorzy
            {
                path: 'authors',
                name: 'cms-authors',
                meta: { auth: true },
                component: () => import('@/modules/cms/authors/views/AuthorsList.vue')
            },
            {
                path: 'authors/sort',
                name: 'cms-authors-sort',
                meta: { auth: true },
                component: () => import('@/modules/cms/authors/views/AuthorsSort.vue')
            },
            {
                path: 'authors/add',
                name: 'cms-authors-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/authors/views/AuthorForm.vue')
            },
            {
                path: 'authors/:id',
                name: 'cms-authors-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/authors/views/AuthorForm.vue')
            },
            {
                path: 'authors/editor',
                name: 'cms-authors-editor',
                meta: { auth: true },
                component: () => import('@/modules/cms/authors/views/AuthorEditorForm.vue')
            },
            // Rss
            // {
            //     path: 'rss',
            //     name: 'cms-rss',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/rss/views/RssList.vue')
            // },
            // Baza dokumentów - dokumenty
            {
                path: 'documents',
                name: 'cms-documents',
                meta: { auth: true },
                component: () => import('@/modules/cms/documents/views/DocumentsList.vue')
            },
            {
                path: 'documents/:id',
                name: 'cms-documents-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/documents/views/DocumentsForm.vue')
            },
            {
                path: 'documents/add',
                name: 'cms-documents-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/documents/views/DocumentsForm.vue')
            },
            // Baza dokumentów - kategorie
            {
                path: 'documents/categories',
                name: 'cms-documents-categories',
                meta: { auth: true },
                component: () => import('@/modules/cms/documents/views/CategoriesList.vue'),
                children: [
                    {
                        path: ':id(\\d+)',
                        props: true,
                        name: 'cms-documents-categories-edit',
                        meta: { auth: true },
                        component: () => import('@/modules/cms/documents/views/CategoriesForm.vue')
                    },
                    {
                        path: 'add',
                        props: true,
                        name: 'cms-documents-categories-add',
                        meta: { auth: true },
                        component: () => import('@/modules/cms/documents/views/CategoriesForm.vue')
                    }
                ]
            },
            // Galeria zdjęć
            {
                path: 'gallery',
                name: 'cms-gallery',
                meta: { auth: true },
                component: () => import('@/modules/cms/gallery/views/GalleryList.vue')
            },
            {
                path: 'gallery/:id',
                name: 'cms-gallery-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.CmsPhotoGallery" },
                component: () => import('@/modules/cms/gallery/views/GalleryForm.vue')
            },
            {
                path: 'gallery/add',
                name: 'cms-gallery-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/gallery/views/GalleryForm.vue')
            },
            // Wideo
            {
                path: 'videos',
                name: 'cms-videos',
                meta: { auth: true },
                component: () => import('@/modules/cms/videos/views/VideosList.vue')
            },
            {
                path: 'videos/:id',
                name: 'cms-videos-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.CmsVideo" },
                component: () => import('@/modules/cms/videos/views/VideoForm.vue')
            },
            {
                path: 'videos/add',
                name: 'cms-videos-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/videos/views/VideoForm.vue')
            },
            // Rotatory opinii
            // {
            //     path: 'opinion-sliders',
            //     name: 'cms-opinion-sliders',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/opinion-sliders/views/OpinionSlidersList.vue')
            // },
            // {
            //     path: 'opinion-sliders/:id',
            //     name: 'cms-opinion-sliders-edit',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/opinion-sliders/views/OpinionSliderForm.vue')
            // },
            // {
            //     path: 'opinion-sliders/add',
            //     name: 'cms-opinion-sliders-add',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/opinion-sliders/views/OpinionSliderForm.vue')
            // },
            // Przekierowania
            {
                path: 'redirections',
                name: 'cms-redirections',
                meta: { auth: true },
                component: () => import('@/modules/cms/redirections/views/Redirections.vue')
            }
        ]
    },
    {
        // Moduły
        path: 'marketing',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // {
            //     path: 'subscriptions',
            //     name: 'cms-subscriptions',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/subscriptions/views/SubscriptionsList.vue')
            // },
            {
                path: 'regulations',
                name: 'cms-regulations',
                meta: { auth: true },
                component: () => import('@/modules/cms/regulations/views/RegulationsList.vue')
            },
            {
                path: 'regulations/:id',
                name: 'cms-regulations-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/regulations/views/RegulationForm.vue')
            },
            {
                path: 'regulations/add',
                name: 'cms-regulations-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/regulations/views/RegulationForm.vue')
            },
            {
                path: 'regulations/sets',
                name: 'cms-regulations-sets',
                meta: { auth: true },
                component: () => import('@/modules/cms/regulations/views/RegulationsSetsList.vue')
            },
            {
                path: 'regulations/sets/:id',
                name: 'cms-regulations-sets-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/regulations/views/RegulationsSetsListItem.vue')
            },
            {
                path: 'regulations/sets/add',
                name: 'cms-regulations-sets-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/regulations/views/RegulationsSetsListItem.vue')
            },
            // Komentarze
            // {
            //     path: 'comments',
            //     name: 'cms-comments',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/comments/views/CommentsList.vue')
            // },
            // {
            //     path: 'comments/sets',
            //     name: 'cms-comments-sets',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/comments/views/CommentsSets.vue')
            // },
            // {
            //     path: 'comments/sets/edit',
            //     name: 'cms-comments-sets-edit',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/comments/views/CommentsSets.vue')
            // },
            // {
            //     path: 'top-topics',
            //     name: 'cms-top-topics',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/top-topics/views/TopTopicsList.vue')
            // },
            // {
            //     path: 'top-topics/:id',
            //     name: 'cms-top-topics-edit',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/top-topics/views/TopTopicsForm.vue')
            // },
            // {
            //     path: 'top-topics/add',
            //     name: 'cms-top-topics-add',
            //     meta: { auth: true },
            //     component: () => import('@/modules/cms/top-topics/views/TopTopicsForm.vue')
            // },
        ]
    },
    {
        path: 'sliders',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'sliders',
                name: 'cms-sliders',
                meta: { auth: true },
                component: () => import('@/modules/cms/sliders/views/SlidersList.vue')
            },
            {
                path: 'sliders/:id',
                name: 'cms-sliders-edit',
                meta: { auth: true },
                component: () => import('@/modules/cms/sliders/views/SliderForm.vue')
            },
            {
                path: 'sliders/add',
                name: 'cms-sliders-add',
                meta: { auth: true },
                component: () => import('@/modules/cms/sliders/views/SliderForm.vue')
            }
        ]
    }
];
