import { axios } from '@/plugins/axios';

/**
 * RecaptchaService
 */
export default class RecaptchaService
{
    /**
     *
     * @returns Promise<Record<string, any>>
     */
    public static async fetch(): Promise<Record<string, any>>
    {
        return (await axios.get<Record<string, any>>(`auth/recaptcha`)).data;
    }

    /**
     * @param model Record<string, any>
     *
     * @returns Promise<Statement>
     */
    public static async testSettings(captchaResult: string, privateKey: string): Promise<boolean>
    {
        return (await axios.post<boolean>(`admin/recaptcha/test/`, {captchaResult, privateKey})).data;
    }
}
