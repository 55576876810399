<template>
    <ideo-form-group :label="$t('[[[Tryb wyświetlania]]]')" :invalid-feedback="form.$errors.first('settings.mode')" :state="form.$errors.state('settings.mode')">
        <ideo-form-radio v-model="form.settings.mode" name="articles" value="single">{{ $t('[[[Pojedynczy artykuł (strona opisowa)]]]') }}</ideo-form-radio>
        <ideo-form-radio v-model="form.settings.mode" name="articles" value="list">{{ $t('[[[Lista artykułów]]]') }}</ideo-form-radio>
    </ideo-form-group>
    <!-- <ideo-form-group :label="$t('[[[Wybierz czołówkę]]]')">
        <ideo-form-group :invalid-feedback="form.$errors.first('isPhotoView')" :state="form.$errors.state('isPhotoView')">
            <ideo-form-checkbox v-model="form.settings.isPhotoView" switch>{{ $t('[[[Widok czołówki - zdjęcie z tytułem]]]') }}</ideo-form-checkbox>
        </ideo-form-group>
        <div class="d-flex flex-column flex-md-row">
            <div class="flex-grow-1">
                <ideo-autocomplete
                    v-model="form.settings.headlineCoverId"
                    name="headlineCoverId"
                    track-by="id"
                    :placeholder="$t('[[[Wyszukaj czołówkę...]]]')"
                    :fetch="(id: number) => fetchHeadlineCover(id)"
                    :search="(query: string, limit: number) => searchHeadlineCover(query, limit)"
                >
                    <template #selected="{ option }">
                        <div class="text-wrap">
                            {{ option.name }}
                        </div>
                    </template>
                    <template #option="{ option }">
                        <div class="text-wrap">
                            {{ option.name }}
                        </div>
                    </template>
                </ideo-autocomplete>
            </div>
        </div>
    </ideo-form-group> -->
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('description')" :state="form.$errors.state('description')">
        <editor id="description" v-model="form.settings.description"></editor>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import ArticleHeadlineCoversService, { ListItemModel } from '@/modules/cms/articles/services/ArticleHeadlineCoversService';
import merge from 'lodash/merge';

@Options({
    name: 'module-articles-settings'
})
export default class ArticlesSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        mode: 'single',
        isPhotoView: false,
        headlineCoverId: null,
        description: ''
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    public async fetchHeadlineCover(id: number): Promise<ListItemModel>
    {
        const response = await ArticleHeadlineCoversService.fetchAutocompleteModel(id);

        return response || null;
    }

    public async searchHeadlineCover(query: string, limit: number): Promise<ListItemModel[]>
    {
        return await ArticleHeadlineCoversService.getAutocompleteList(query, limit);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
