<template>
    <ideo-form-group :label="$t('[[[Typ rankingu]]]')" :invalid-feedback="form.$errors.first('settings.rankingType')" :state="form.$errors.state('settings.rankingType')">
        <ideo-form-select v-model="form.settings.rankingType" :options="rankingTypeOptions" value-field="value" text-field="text" @change="rankingTypeChanged" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Ilość wyświetlanych artykułów]]]')" :invalid-feedback="form.$errors.first('settings.quantity')" :state="form.$errors.state('settings.quantity')">
        <ideo-form-input name="quantity" v-model="form.settings.quantity" type="number"></ideo-form-input>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';

@Options({
    name: 'widget-articles-ranking-settings'
})
export default class ArticlesRankingSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        rankingType: 'Date',
        quantity: 5
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public get rankingTypeOptions(): Option[]
    {
        return [
            { value: 'Views', text: this.$t('[[[Liczba wyświetleń]]]') },
            { value: 'UserInteraction', text: this.$t('[[[Interakcja użytkownika]]]') },
            { value: 'TimeSpent', text: this.$t('[[[Czas na stronie]]]') },
        ];
    }

    public async rankingTypeChanged(): Promise<void>
    {
        this.form.settings = {
            rankingType: this.form.settings.rankingType
        };
    }
}
</script>
