<template>
    <div>
        <ideo-form-group :label="$t('[[[Wersje dokumentu]]]')" tag="h5">
            <div class="d-flex flex-column gap-2">
                <div v-if="versions.length == 0" class="border rounded d-flex align-items-center justify-content-center py-2">
                    {{ $t('[[[Brak wersji]]]') }}
                </div>
                <template v-else>
                    <div
                        v-for="item in versions"
                        :key="item.id"
                        class="version-item d-flex align-items-center rounded p-2"
                        :class="{'bg-primary-subtle': item.id == current}"
                        @click="onClick(item.id)"
                    >
                        <i :class="statusIcon(item.status, item.id == current)" class="ms-2 me-3 fs-4"></i>

                        <div class="me-2">
                            <span class="d-block" :title="$t('[[[Data utworzenia wersji]]]')">
                                <i class="far fa-fw fa-clock"></i>
                                <span class="ms-2">{{ $filters.datetime(item.dateModifiedUtc) }}</span>
                            </span>
                            <span class="d-block" :title="$t('[[[Wersja utowrzona przez]]]')">
                                <i class="far fa-fw fa-user"></i>
                                <span class="ms-2">{{ item.modifiedByUser }}</span>
                            </span>
                        </div>

                        <ideo-button v-if="item.id != current" class="ms-auto" variant="none" :no-border="true" size="sm" pill :title="$t('[[[Porównaj z edytowaną wersją]]]')" @click.stop.prevent="onCompare(item.id)">
                            <i class="fas fa-arrow-right-arrow-left fs-5"></i>
                        </ideo-button>
                    </div>
                </template>
            </div>
            <action-button variant="secondary" class="w-100 mt-2" icon="fas fa-arrow-down" :text="$t('[[[Pokaż więcej]]]')" @click.prevent="onMore()" v-if="versions.length > 0 && pager.pageIndex < pager.totalPages" />
        </ideo-form-group>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { PublicationEnum, PublicationName, PublicationStyle, PublicationIcon } from '@/helpers/Enums';
import { EntityVersion } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';

@Options({
    name: 'entity-versions'
})
export default class EntityVersions extends Vue
{
    @Prop() public value: EntityVersion[];
    @Prop({ default: 0 }) public current: number;
    @Prop({ default: false }) public disabled: boolean;
    @Prop() public pager!: Pager;
    @Prop({ default: true }) public canCompare: boolean;

    public compare: number = 0;

    public get versions(): EntityVersion[]
    {
        return this.value || [];
    }

    public get statusEnum(): typeof PublicationEnum
    {
        return PublicationEnum;
    }

    public statusName(status: PublicationEnum): string
    {
        return PublicationName(status);
    }

    public statusIcon(status: PublicationEnum, selected: boolean): string
    {
        return PublicationIcon(status);
    }

    public statusStyle(classTemplate: string, status: PublicationEnum): Record<string, boolean>
    {
        return PublicationStyle(classTemplate, status);
    }

    @Emit('select')
    public onClick(id: number): number
    {
        this.onCompare(0);

        return id;
    }

    @Emit('compare')
    public onCompare(id: number): number
    {
        this.compare = id;

        return id;
    }

    @Emit('more')
    public onMore(): void
    {
    }
}
</script>

<style lang="scss" scoped>
.version-item {
    background: var(--bs-light);
    cursor: pointer;
}
</style>
