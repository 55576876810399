<template>
    <ideo-form-group :label="$t('[[[Wybór strony]]]')" :invalid-feedback="form.$errors.first('settings.articlesPage')" :state="form.$errors.state('settings.articlesPage')">
        <edito-page-selector v-model="form.settings.articlesPage" :module="module" label="" :preview="false" class="mb-0" />
    </ideo-form-group>

    <ideo-form-group :label="$t('[[[Wybierz kategorię]]]')">
        <div class="d-flex flex-column flex-md-row">
            <div class="flex-grow-1">
                <ideo-autocomplete
                    v-model="categoryId"
                    name="categoryId"
                    track-by="id"
                    :placeholder="$t('[[[zacznij pisać aby wyszukać kategorię...]]]')"
                    :fetch="(id: number) => fetchCategory(id)"
                    :search="(query: string, limit: number) => searchCategories(query, limit)"
                >
                    <template #selected="{ option }">
                        <div class="text-wrap">
                            {{ option.name }}
                        </div>
                    </template>
                    <template #option="{ option }">
                        <div class="text-wrap">
                            {{ option.name }}
                        </div>
                    </template>
                </ideo-autocomplete>
            </div>
            <div class="d-flex align-items-stretch">
                <button class="btn btn-secondary text-nowrap mt-2 mt-md-0 ms-md-2" @click.prevent="addCategory()">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Powiązane kategorię]]]')">
        <div class="list-group">
            <div v-for="item in categories" :key="item.id" class="list-group-item list-group-item-action p-2 d-flex">
                <span class="me-2">
                    <i class="fad fa-fw fa-browser text-primary"></i>
                </span>
                <span class="me-auto">
                    <span class="d-block" :title="$t('[[[Powiązana kategoria]]]')">
                        <b>{{ item.name }}</b>
                    </span>
                </span>
                <span class="d-flex align-items-center">
                    <ideo-button variant="danger" size="sm" pill :id="`btn-delete-related-${item.id}`" :title="$t('[[[Usuń kategorię]]]')">
                        <i class="fas fa-trash-alt"></i>
                    </ideo-button>
                    <confirmation :message="$t('[[[Potwierdzenie usunięcia]]]')" :target="`btn-delete-related-${item.id}`" :value="item.id" @confirm="removeCategory(item.id)" />
                </span>
            </div>
            <div class="list-group-item list-group-item-light text-center" v-if="categories.length == 0">
                {{ $t('[[[Nie wybrano żadnej kategorii]]]') }}
            </div>
        </div>
    </ideo-form-group>

    <ideo-form-group :label="$t('[[[Wybierz domyślną kategorię]]]')" :invalid-feedback="form.$errors.first('settings.defaultCategoryId')" :state="form.$errors.state('settings.defaultCategoryId')">
        <ideo-form-select name="defaultCategoryId" v-model="form.settings.defaultCategoryId" :options="categories" value-field="id" text-field="name" />
    </ideo-form-group>

    <ideo-form-group>
        <ideo-form-checkbox v-model="form.settings.priority">
            {{ $t('[[[Artykuły ze statusem PRIORYTET]]]') }}
        </ideo-form-checkbox>
    </ideo-form-group>

    <ideo-form-group :label="$t('[[[Powiązane artykuły]]]')">
        <div class="list-group">
            <div v-for="item in related" :key="item.id" class="list-group-item list-group-item-action p-2 d-flex">
                <span class="me-2">
                    <i class="fad fa-fw fa-browser text-primary"></i>
                </span>
                <span class="me-auto">
                    <span class="d-block" :title="$t('[[[Powiązany artykuł]]]')">
                        <b>{{ item.title }}</b>
                    </span>
                </span>
            </div>
            <div class="list-group-item list-group-item-light text-center" v-if="related.length == 0">
                {{ $t('[[[Brak powiązań]]]') }}
            </div>
        </div>
    </ideo-form-group>

    <ideo-form-group :label="$t('[[[Liczba artykułów]]]')" :invalid-feedback="form.$errors.first('settings.quantity')" :state="form.$errors.state('settings.quantity')">
        <ideo-form-input type="number" v-model="form.settings.quantity" name="quantity"></ideo-form-input>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import { $t } from '@/plugins/localization';
import ArticlesService, { ListItemModel } from '@/modules/cms/articles/services/ArticlesService';

import ArticleCategoriesService, { ListItemModel as CategoryListItemModel } from '@/modules/cms/articles/services/ArticleCategoriesService';
import merge from 'lodash/merge';

@Options({
    name: 'widget-articles-list-settings'
})
export default class ArticlesListSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public module: string = 'articles';
    public defaults: any = {
        articlesPage: null,
        selectedCategories: [],
        defaultCategoryId: null,
        quantity: 3,
        priority: false
    };

    public categoryId: number|null = null;
    public categories: CategoryListItemModel[] = [];
    public related: ListItemModel[] = [];

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
        this.loadCategories(this.form.id);
    }

    @Watch('form.settings', { deep: true })
    public onDefaultCategoryChanged(value: any): void
    {
        this.loadRelated(this.defaults.defaultCategoryId);
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public async fetchCategory(id: number): Promise<CategoryListItemModel>
    {
        const response = await ArticleCategoriesService.fetchAutocompleteModel(id);

        return response || null;
    }

    public async searchCategories(query: string = '', limit: number = 10): Promise<CategoryListItemModel[]>
    {
        return await ArticleCategoriesService.getAutocompleteList(query, limit);
    }

    public async addCategory():Promise<void>
    {
        if (this.categoryId == null)
            return;

        const response = await ArticleCategoriesService.fetchAutocompleteModel(this.categoryId);

        if (this.categories.find(x => x.id === response.id) === undefined &&
            this.form.settings.selectedCategories.find(x => x === response.id) === undefined)
        {
            this.categories.push(response);
            this.form.settings.selectedCategories.push(response.id);
        }

        this.categoryId = null;
    }

    public removeCategory(id: number): void
    {
        this.categories = this.categories.filter(x => x.id != id);
        this.form.settings.selectedCategories = this.form.settings.selectedCategories.filter(x => x != id);
    }

    public async loadCategories(id: number): Promise<boolean>
    {
        if (id === 0)
            return true;

        try
        {
            this.categories = await ArticleCategoriesService.getSelectedFromWidget(id);
        }
        catch (ex)
        {
            return false;
        }

        return true;
    }

    public async loadRelated(categoryId: number): Promise<boolean>
    {
        if (categoryId === null)
            categoryId = 0;

        try
        {
            this.related = await ArticlesService.getListByCategoryId(categoryId);
        }
        catch (ex)
        {
            return false;
        }

        return true;
    }
}
</script>
