<template>
    <ideo-form-group :invalid-feedback="form.$errors.first('settings.showTitle')" :state="form.$errors.state('settings.showTitle')" :nospace="true">
        <ideo-form-checkbox v-model="form.settings.showTitle">{{ $t('[[[Wyświetlaj tytuł]]]') }}</ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Tytuł okładki]]]')" :invalid-feedback="form.$errors.first('settings.title')" :state="form.$errors.state('settings.title')">
        <ideo-form-input type="text" v-model="form.settings.title" name="title"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[YouTube (ID)]]]')" :invalid-feedback="form.$errors.first('settings.youtubeId')" :state="form.$errors.state('settings.youtubeId')">
        <ideo-form-input type="text" v-model="form.settings.youtubeId" name="youtubeId"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Zdjęcie]]]')" :invalid-feedback="form.$errors.first(`settings.image`)" :state="form.$errors.state(`settings.image`)">
        <filepicker id="image" v-model="form.settings.image" media="images" />
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';

@Options({
    name: 'widget-video-settings'
})
export default class VideoSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        title: '',
        showTitle: false,
        youtubeId: '',
        image: null
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }
}
</script>
