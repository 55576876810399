<script setup lang="ts">
import { computed } from 'vue';
import { TreeNodeCoursesProgram } from '../utils';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import Draggable from 'vuedraggable';

defineOptions({
    name: 'ideo-tree-courses-program-nodes'
});

const props = defineProps({
  "pages": null,
  "expanded": null,
  "archive": { type: Boolean, default: false },
  "parent": { default: null },
  "userProgram": { type: Boolean, default: false },
  "menu": { type: Function, default: null },
  "move": { type: Function, default: () => false },
  "iconVisible": { type: Boolean, default: false }
});

const emit = defineEmits(["toggle", "click", "sort", "delete", "restore", "resetUnit"]);

const onToggle = (node: TreeNodeCoursesProgram): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNodeCoursesProgram): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNodeCoursesProgram[]): void =>
{
    emit('sort', pages);
};

const onDelete = (node: TreeNodeCoursesProgram): void =>
{
    emit('delete', node);
};

const onRestore = (node: TreeNodeCoursesProgram): void =>
{
    emit('restore', node);
};

const resetUnitResult = (node: TreeNodeCoursesProgram): void =>
{
    emit('resetUnit', node);
};

const isExpanded = computed(() =>
{
    return props.parent == null || (props.parent.hasChildren && props.expanded.indexOf(props.parent.id) >= 0);
});

const children = computed({
    get(): TreeNodeCoursesProgram[]
    {
        return sortBy(
            filter(props.pages, p => p.parentId === (props.parent ? props.parent.id : null)), s => s.position
        );
    },
    set(pages: TreeNodeCoursesProgram[])
    {
        forEach(pages, (page: any, i: number) =>
        {
            page.position = i + 1;
        });

        onSort(pages);
    }
});

</script>

<template>
    <Draggable v-model="children" :move="move" item-key="id" tag="ul" class="w-100" v-if="isExpanded">
        <template v-if="archive" #item="{element}">
            <ideo-tree-archive-program-node :pages="pages" :expanded="expanded" :node="element" :menu="menu" :move="move" :icon-visible="iconVisible" @toggle="onToggle" @click="onClick" @sort="onSort" @delete="onDelete" @restore="onRestore" />
        </template>
        <template v-else-if="userProgram" #item="{element}">
            <ideo-tree-user-course-program-node :pages="pages" :expanded="expanded" :node="element" :menu="menu" :move="move" :icon-visible="iconVisible" @toggle="onToggle" @click="onClick" @sort="onSort" @resetUnit="resetUnitResult" />
        </template>
        <template v-else #item="{element}">
            <ideo-tree-courses-program-node :pages="pages" :expanded="expanded" :node="element" :menu="menu" :move="move" :icon-visible="iconVisible" @toggle="onToggle" @click="onClick" @sort="onSort" @resetUnit="resetUnitResult" @delete="onDelete" />
        </template>
    </Draggable>
</template>
