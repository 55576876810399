<script lang="ts" setup>
import { shallowRef, onBeforeMount, defineAsyncComponent } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { CompositeType } from '.';
import * as Vue from 'vue';
import { loadModule } from "vue3-sfc-loader";
import { axios } from '@/plugins/axios';
import { useLogging } from '@/plugins/logging';

defineOptions({
    name: 'composite-blueprint'
});

const props = defineProps({
  "blueprint": { default: null },
  "entry": { default: null },
  "form": { default: null },
  "parent": { default: null }
});

const { $log } = useLogging();
const module = shallowRef(null);

const imports: Record<string, any> = {
    // Base blueprints
    '@/components/builder/base/blueprints/AggregateBlueprint': import('@/components/builder/base/blueprints/AggregateBlueprint'),
    '@/components/builder/base/blueprints/Blueprint': import('@/components/builder/base/blueprints/Blueprint'),
    '@/components/builder/base/blueprints/RootBlueprint': import('@/components/builder/base/blueprints/RootBlueprint'),
    '@/components/builder/base/blueprints/ValidatableBlueprint': import('@/components/builder/base/blueprints/ValidatableBlueprint'),
    // Base traits
    '@/components/builder/base/traits/HasWidth': import('@/components/builder/base/traits/HasWidth'),
    // Base types
    '@/components/builder/base/types/Identity': import('@/components/builder/base/types/Identity'),
    '@/components/builder/base/types/ValidationErrors': import('@/components/builder/base/types/ValidationErrors'),
    // Form blueprints
    '@/components/builder/form/blueprints/CustomErrorBlueprint': import('@/components/builder/form/blueprints/CustomErrorBlueprint'),
    '@/components/builder/form/blueprints/FormBlueprint': import('@/components/builder/form/blueprints/FormBlueprint'),
    '@/components/builder/form/blueprints/PageBlueprint': import('@/components/builder/form/blueprints/PageBlueprint'),
    '@/components/builder/form/blueprints/ReadonlyBlueprint': import('@/components/builder/form/blueprints/ReadonlyBlueprint'),
    '@/components/builder/form/blueprints/RequiredBlueprint': import('@/components/builder/form/blueprints/RequiredBlueprint'),
    '@/components/builder/form/blueprints/RowBlueprint': import('@/components/builder/form/blueprints/RowBlueprint'),
    '@/components/builder/form/blueprints/VisibleBlueprint': import('@/components/builder/form/blueprints/VisibleBlueprint'),
    // Form entries
    '@/components/builder/form/entries/Entry': import('@/components/builder/form/entries/Entry'),
    '@/components/builder/form/entries/RootEntry': import('@/components/builder/form/entries/RootEntry'),
    '@/components/builder/form/entries/SecureEntry': import('@/components/builder/form/entries/SecureEntry'),
    '@/components/builder/form/entries/ValidEntry': import('@/components/builder/form/entries/ValidEntry'),
    // Form enums
    '@/components/builder/form/enums/AlwaysChoice': import('@/components/builder/form/enums/AlwaysChoice'),
    '@/components/builder/form/enums/InternallyChoice': import('@/components/builder/form/enums/InternallyChoice'),
    '@/components/builder/form/enums/NeverChoice': import('@/components/builder/form/enums/NeverChoice'),
    '@/components/builder/form/enums/WhenChoice': import('@/components/builder/form/enums/WhenChoice'),
    // Form traits
    '@/components/builder/form/traits/EntryFactory': import('@/components/builder/form/traits/EntryFactory'),
    '@/components/builder/form/traits/HasAffix': import('@/components/builder/form/traits/HasAffix'),
    '@/components/builder/form/traits/HasDescription': import('@/components/builder/form/traits/HasDescription'),
    '@/components/builder/form/traits/HasFilter': import('@/components/builder/form/traits/HasFilter'),
    '@/components/builder/form/traits/HasHelp': import('@/components/builder/form/traits/HasHelp'),
    '@/components/builder/form/traits/HasLabel': import('@/components/builder/form/traits/HasLabel'),
    '@/components/builder/form/traits/HasPlaceholder': import('@/components/builder/form/traits/HasPlaceholder'),
    '@/components/builder/form/traits/HasRepetitions': import('@/components/builder/form/traits/HasRepetitions'),
    '@/components/builder/form/traits/HasTitle': import('@/components/builder/form/traits/HasTitle'),
    '@/components/builder/form/traits/Relational': import('@/components/builder/form/traits/Relational'),
    // Form types
    '@/components/builder/form/types/AffixValue': import('@/components/builder/form/types/AffixValue'),
    '@/components/builder/form/types/ChoiceOption': import('@/components/builder/form/types/ChoiceOption'),
    '@/components/builder/form/types/MinMaxValue': import('@/components/builder/form/types/MinMaxValue'),
    '@/components/builder/form/types/PeriodValue': import('@/components/builder/form/types/PeriodValue'),
    '@/components/builder/form/types/ProcessCallback': import('@/components/builder/form/types/ProcessCallback'),
    // Form builder
    '@/components/builder/form': import('@/components/builder/form'),
    // Form properties
    '@/components/forms/properties': import('@/components/forms/properties'),
    // Interfaces
    '@/helpers/Form': import('@/helpers/Form'),
    '@/helpers/Interfaces': import('@/helpers/Interfaces'),
    '@/helpers/Pager': import('@/helpers/Pager'),
    '@/helpers/Utils': import('@/helpers/Utils'),
    // Plugins
    '@/plugins/alerts': import('@/plugins/alerts'),
    '@/plugins/localization': import('@/plugins/localization'),
    '@/plugins/logging': import('@/plugins/logging'),
    // Composite types
    'composite': import('./'),
    '.': import('./'),
    // Packages
    'axios': Promise.resolve(axios),
};

onBeforeMount(async () =>
{
    try
    {
        const key = props.blueprint.composite;
        const fileName = `${key}.vue`;

        const options = {
            moduleCache: {vue: Vue},
            async getFile(url: string)
            {
                if (url != fileName)
                    throw new Error(`File "${url}" not allowed.`);

                const response = await axios.get<string>(`admin/studio/components/${key}.vue`, {
                    transformRequest: (data, headers) =>
                    {
                        headers['Accept-Language'] = ''; // wyłączenie tłumaczeń
                    }
                });

                if (response.status != 200)
                    throw new Error("Network response was not OK");

                return response.data;
            },
            async loadModule(path: string)
            {
                if (path in imports)
                    return await imports[path];
            },
            addStyle(styleString: string)
            {
                let style = document.getElementById(key);

                if (!style)
                {
                    style = document.createElement('style');
                    style.setAttribute('id', key);

                    const ref = document.head.getElementsByTagName('style')[0] || null;

                    document.head.insertBefore(style, ref);
                }

                style.textContent = styleString;
            }
        };

        module.value = defineAsyncComponent(() => loadModule(fileName, options));
    }
    catch (ex)
    {
        $log.error(ex);
    }
});
</script>

<template>
    <component
        :is="module"
        :blueprint="props.blueprint"
        :entry="props.entry"
        :form="props.form"
        :parent="props.parent"
        v-if="module"
    ></component>
</template>
