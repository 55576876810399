<template>
    <ideo-form-group :label="$t('[[[Moduł konfigurowalny]]]')" :invalid-feedback="form.$errors.first('moduleId')" :state="form.$errors.state('moduleId')" required>
        <ideo-form-select v-model="form.settings.moduleId" type="text" name="moduleId" :options="modules"></ideo-form-select>
    </ideo-form-group>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import ModulesService, { FormModel as ModuleModel } from '@/modules/studio/modules/services/ModulesService';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';

defineOptions({
    name: 'module-dynamic-modules-settings'
});

const { $alert } = useAlerts();
const { $log } = useLogging();

const model = defineModel<any>();
const defaults: any = {
    moduleId: null
};
const modules = ref<Option<number>[]>([]);
const form = computed(() => model.value);

onMounted(async () =>
{
    form.value.settings = {...defaults, ...form.value.settings};

    await loadModules();
});

watch(() => form.value?.settings, () =>
{
    model.value = form.value;
},
{ deep: true });

async function loadModules()
{
    try
    {
        const pager = new Pager(1, 10000, 'Name', 'ASC');
        const response = await ModulesService.getList({} as any, pager);

        modules.value = [{value: null, text: ''}, ...response.items.map(p => ({
            value: p.id,
            text: p.name
        }))];
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return false;
    }

    return true;
}
</script>
