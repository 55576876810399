<template>
    <div class="grid-stack-item" v-bind="gridStackAttributes">
        <div class="grid-stack-item-content" :class="{ move: isEditMode }">
            <DataCard class="overflow-hidden" :scroll="true" :overflow="true">
                <template #header>
                    <div class="d-flex justify-content-between gap-2 align-items-center">
                        <h5 class="m-0">
                            {{ widget.name }}
                            {{ month(widget.config) }}
                        </h5>
                        <div class="d-flex align-items-center gap-2">
                            <PortalTarget :name="`base-widget-header-${widget.publicId}`" />
                            <IdeoDropdown variant="light" ref="ideo-dropdown" :text="$t('[[[Akcje]]]')" dropleft no-caret class="options">
                                <template #button-content>
                                    <i class="fal fa-ellipsis-v-alt"></i>
                                </template>
                                <template #default>
                                    <IdeoDropdownItemButton @click="changeSettings">
                                        <i class="icon far fa-gear"></i>
                                        <span>{{ $t("[[[Ustawienia]]]") }}</span>
                                    </IdeoDropdownItemButton>
                                    <IdeoDropdownItemButton @click="deleteWidget">
                                        <i class="icon far fa-trash-alt"></i> {{ $t("[[[Usuń]]]") }}
                                    </IdeoDropdownItemButton>
                                </template>
                            </IdeoDropdown>
                        </div>
                    </div>
                </template>
                <template #default>
                    <template v-if="getWidgetComponent(widget.config)">
                        <component :is="getWidgetComponent(widget.config)" :key="widget.config.sourceConfig" :public-id="widget.publicId" :config="widget.config"></component>
                        <!-- <div class="d-flex align-items-center gap-2 pt-3 pb-2">
                            <PortalTarget :name="`base-widget-footer-${widget.publicId}`" />
                        </div> -->
                    </template>
                    <span v-else>{{ $t("[[[Nie znaleziono pasującego widgetu]]]") }}</span>
                </template>
            </DataCard>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "@/helpers/Decorators";
import { GridItemAttributes, Widget, WidgetTypeEnum, WidgetModel } from "@/modules/core/home/services/HomeService";
import ActiveSubstitutions from "@/modules/core/home/components/widgets/ActiveSubstitutions.vue";
import AiUsage from '@/modules/core/home/components/widgets/AiUsage.vue';
import AllCourses from "@/modules/core/home/components/widgets/AllCourses.vue";
import AllUsers from "@/modules/core/home/components/widgets/AllUsers.vue";
import Coordinators from "@/modules/core/home/components/widgets/Coordinators.vue";
import Logins from "@/modules/core/home/components/widgets/Logins.vue";
import NewUsers from "@/modules/core/home/components/widgets/NewUsers.vue";
import Statistics from "@/modules/core/home/components/widgets/Statistics.vue";
import { MonthOptionName } from '@/helpers/Enums';

@Options({
    name: "BaseWidget",
    emits: ["delete-widget"],
    components: {
        ActiveSubstitutions,
        AiUsage,
        AllCourses,
        AllUsers,
        Coordinators,
        Logins,
        NewUsers,
        Statistics
    }
})
export default class BaseWidget extends Vue
{
    @Prop({ default: () => ({}) })
    public widget: Widget;

    @Prop({ default: false })
    public isEditMode: boolean;

    public get gridStackAttributes(): GridItemAttributes
    {
        return {
            id: `x${this.widget.publicId}`,
            "gs-id": `x${this.widget.publicId}`,
            "gs-x": this.widget.x,
            "gs-y": this.widget.y,
            "gs-w": this.widget.w,
            "gs-h": this.widget.h,
            "gs-no-resize": true,
        };
    }

    public month(widget: WidgetModel): string
    {
        if (widget.type === WidgetTypeEnum.Statistics)
        {
            const month = (new Date()).getMonth();

            return  this.$t('[[[ dodanych w ]]]') + MonthOptionName(month);
        }

        return '';
    }

    public getWidgetComponent(widget: WidgetModel): string
    {
        switch (widget.type)
        {
            case WidgetTypeEnum.ActiveSubstitutions:
                return "ActiveSubstitutions";
            case WidgetTypeEnum.AiUsage:
                return "AiUsage";
            case WidgetTypeEnum.AllCourses:
                return "AllCourses";
            case WidgetTypeEnum.AllUsers:
                return "AllUsers";
            case WidgetTypeEnum.Coordinators:
                return "Coordinators";
            case WidgetTypeEnum.Logins:
                return "Logins";
            case WidgetTypeEnum.NewUsers:
                return "NewUsers";
            case WidgetTypeEnum.Statistics:
                return "Statistics";
        }
    }

    public changeSettings(): void
    {
        this.$events.$emit("widget-settings-modal", { publicId: this.widget.publicId });
    }

    @Emit("delete-widget")
    public deleteWidget(): void
    {
    }
}
</script>
<style lang="scss" scoped>
.grid-stack-item-content {
    display: flex;
    flex-direction: column;
    position: relative;

    &.move {
        cursor: move;
    }

    :deep(.card-form) {
        height: 100%;
        min-height: unset;
    }
}
</style>
