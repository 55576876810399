<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import properties from '../../properties';
import { FormEntry } from '../form';
import { instanceOfRegulationsEntry, RegulationsType, RegulationsEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import RegulationsSetsService from '@/modules/cms/regulations/services/RegulationsSetsService';
import RegulationsService from '@/modules/cms/regulations/services/RegulationsService';
import { computedAsync } from '@vueuse/core';
import WebsitesService from '@/modules/cms/websites/services/WebsitesService';

defineOptions({
    name: 'regulations-blueprint',
    components: {
        ...properties,
    },
});

const { $log } = useLogging();
const { $t, $i18n } = useLocalization();

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new RegulationsEntry()) as Ref<RegulationsEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRegulationsEntry);

const checked = computed({
    get()
    {
        return entryData.value.data.checked || [];
    },
    set(value: number[])
    {
        entryData.value.data.checked = value;
    },
});

const required = computed({
    get()
    {
        return entryData.value.data.required || [];
    },
    set(value: number[])
    {
        entryData.value.data.required = value;
    },
});

const websites = computedAsync(async () =>
{
    try
    {
        return await WebsitesService.getOptions();
    }
    catch (ex)
    {
        $log.debug(ex);
    }
});
const websitesOptions = computed(() => websites.value?.map((p) => ({ value: p.id, text: p.title })));

const website = computed(() => websites.value?.find((p) => p.id == blueprint.value.websiteId));
const locales = computed(
    () =>
        website.value?.locales.map((code) => ({
            value: code,
            text: $i18n.options().find((p) => p.value == code)?.text,
        })) || []
);

const regulationsSetsOptions = computedAsync(async () =>
{
    try
    {
        return await RegulationsSetsService.getForWebsite(blueprint.value.websiteId, blueprint.value.locale);
    }
    catch (ex)
    {
        $log.debug(ex);
    }
});

const regulationsList = computedAsync(async () =>
{
    try
    {
        const regulations = await RegulationsService.getListBySetId(blueprint.value.regulationSetId);

        required.value = regulations.filter((x) => x.isRequired).map((x) => x.id);

        return regulations;
    }
    catch (ex)
    {
        $log.debug(ex);
    }
});

watch(
    () => blueprint.value.locale,
    () =>
    {
        blueprint.value.regulationSetId = 0;
    }
);

watch(
    () => blueprint.value.websiteId,
    () =>
    {
        blueprint.value.locale = null;
        blueprint.value.regulationSetId = 0;
    }
);
</script>

<template>
    <form-component-wrapper class="regulations-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :entry="entryData" />
                <div class="pt-1 row">
                    <div v-for="regulation in regulationsList" :key="regulation.id" class="d-inline">
                        <ideo-form-checkbox v-model="checked" :value="regulation.id">
                            {{ regulation.title }}
                            <i
                                v-if="regulation.isRequired"
                                class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger"
                            ></i>
                        </ideo-form-checkbox>
                    </div>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-select
                v-if="websitesOptions"
                v-model="blueprint.websiteId"
                :options="websitesOptions"
                :label="$t('[[[Witryna]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'websiteId')"
            />
            <field-select
                v-if="blueprint.websiteId > 0"
                v-model="blueprint.locale"
                :options="locales"
                :label="$t('[[[Wersja językowa]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'locale')"
            />
            <field-select
                v-if="blueprint.locale"
                v-model="blueprint.regulationSetId"
                :options="regulationsSetsOptions"
                :label="$t('[[[Zestaw]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'regulationSetId')"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
        </template>
    </form-component-wrapper>
</template>
