<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { TreeNodeCoursesProgram } from '../utils';
import { ModulesKey } from '@/helpers/Enums';

const route = useRoute();
const { $events } = useEvents();
const { $alert } = useAlerts();
const { $t } = useLocalization();

defineOptions({
    name: 'ideo-tree-user-course-program-node'
});

const props = defineProps({
  "node": null,
  "pages": null,
  "expanded": null,
  "menu": { type: [null, Function],  },
  "move": { type: Function,  },
  "iconVisible": { type: Boolean, default: false }
});

const emit = defineEmits(["toggle", "click", "delete", "resetUnit", "restore", "sort"]);

const hover = ref(false);
const contextMenu = ref(false);
const menuItems = ref([]);

const hasChildren = computed(() => props.node.hasChildren);
const nodeComplete = computed(() => props.node.complete);
const hasMenu = computed(() => props.menu != null);
const isExpanded = computed(() => hasChildren.value && props.expanded.indexOf(props.node.id) >= 0);
const isCollapsed = computed(() => hasChildren.value && props.expanded.indexOf(props.node.id) === -1);
const editableNode = ref<TreeNodeCoursesProgram>();

const isActive = computed(() =>
{
    return route.params.id === props.node.id.toString();
});

const container = computed(() => `node-container-${props.node.id}`);

function menuItemId(index: number)
{
    return `dropdown-${props.node.id}-item-${index}`;
}

const hidePopover = (node: TreeNodeCoursesProgram): void =>
{
    if (node === null || node != props.node)
    {
        menuItems.value = [];
        contextMenu.value = false;
        hover.value = false;
    }
};

watch(nodeComplete, () =>
{
    copyNode();
});

onMounted(async () =>
{
    $events.$on('tree::popover::hide', hidePopover);
    menuItems.value = await getMenu(props.node);
    copyNode();
});

onBeforeUnmount(() =>
{
    $events.$off('tree::popover::hide', hidePopover);
});

const copyNode = () =>
{
    editableNode.value = JSON.parse(JSON.stringify(props.node));
};

const getMenu = async (node: TreeNodeCoursesProgram): Promise<any[]> =>
{
    if (props.menu)
        return await props.menu(node);
    else
        return [];
};

const icon = (icon: string): string =>
{
    let icons = icon;

    if (props.node.isActive)
    {
        icons += ' text-success';
    }

    return props.node.icon ? props.node.icon : icons;
};

const mouseOver = (): void =>
{
    hover.value = true;
};

const mouseOut = (): void =>
{
    if (contextMenu.value == false)
    {
        hover.value = false;
    }
};

const togglePopover = async (): Promise<void> =>
{
    if (contextMenu.value == false)
    {
        $events.$emit('tree::popover::hide', props.node);
    }

    contextMenu.value = !contextMenu.value;

    if (contextMenu.value)
    {
        menuItems.value = await getMenu(props.node);

        if (menuItems.value.length === 0)
        {
            $alert.info($t('[[[Ten element nie zawiera menu kontekstowego.]]]'));
            contextMenu.value = false;
        }
    }
};

const onMenuItem = (node: TreeNodeCoursesProgram, item: any, confirmed: boolean): void =>
{
    if (confirmed)
    {
        $events.$emit('tree::popover::hide', node);
        togglePopover();
        item.click(node);
    }
};

const onMenuItemIcon = (node: TreeNodeCoursesProgram, item: any, confirmed: boolean): void =>
{
    if (confirmed)
    {
        item.click(node);
    }
};

function resetUnitResult(node: TreeNodeCoursesProgram)
{
    if (node.module != ModulesKey.TOPICS)
        emitResetUnitResult(node);
}

function updateNode(node: TreeNodeCoursesProgram): void
{
    if (!node.isPassed)
    {
        $events.$emit('all-items', node);
    }
}

const onToggle = (node: TreeNodeCoursesProgram): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNodeCoursesProgram): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNodeCoursesProgram[]): void =>
{
    emit('sort', pages);
};

const emitResetUnitResult = (node: TreeNodeCoursesProgram): void =>
{
    emit('resetUnit', node);
};

const onRestore = (node: TreeNodeCoursesProgram): void =>
{
    emit('restore', node);
};
</script>

<template>
    <li :id="container" :class="{'active': isActive}" @mouseover.stop="mouseOver" @mouseout.stop="mouseOut">
        <span class="d-flex align-items-baseline">
            <ideo-form-checkbox v-if="node.module!=ModulesKey.TOPICS && editableNode" v-model="editableNode.complete" :disabled="node.isPassed" type="text" name="isPublished" @update:modelValue="updateNode(editableNode)" />
            <i class="fa fa-fw" v-if="!hasChildren"></i>
            <i class="fal fa-fw fa-plus" v-if="isCollapsed" @click.prevent="onToggle(node)"></i>
            <i class="fal fa-fw fa-minus" v-if="isExpanded" @click.prevent="onToggle(node)"></i>
            <i :class="icon('fad fa-fw fa-file fa-swap')" v-if="!hasChildren"></i>
            <i :class="icon('fa fa-fw fa-folder')" v-if="isCollapsed"></i>
            <i :class="icon('fa fa-fw fa-folder-open fa-swap-opacity')" v-if="isExpanded"></i>
            <a href="#" @click.prevent="onClick(node)" class="flex-fill" :class="{'text-dark': isActive}">{{ node.name }}</a>
            <span class="d-flex justify-content-end">
                <span v-if="node.module != ModulesKey.TOPICS" class="d-flex justify-content-center align-items-center" :style="{'width': '140px'}">
                    <i :class="['fa fa-fw fa-circle me-2', node.isRequired ? 'text-success' : 'text-default']" :title="node.isRequired ? $t('[[[element wymagany]]]') : $t('[[[element niewymagany]]]')"></i>
                    <i class="fas fa-fw fa-arrows-rotate cursor-pointer" :id="`btn-reset-${node.id}`" :title="$t('[[[zresetuj podejście do elementu szkolenia]]]')"></i>
                    <confirmation :message="$t('[[[Potwierdzenie resetu podejścia do elementu szkolenia]]]')" :target="`btn-reset-${node.id}`" :value="node.id" @confirm="resetUnitResult(node)" />
                </span>
                <span v-if="hasMenu">
                    <template v-for="(item, index) in menuItems" :key="index">
                        <span v-show="item.name" :id="menuItemId(index)" @click.prevent="onMenuItemIcon(node, item, !item.confirm)" :title="item.name">
                            <i class="me-2 icon" :class="[item.icon, item.class]"></i>
                            <confirmation v-if="item.confirm" :target="menuItemId(index)" :message="item.confirm" :value="item" @confirm="onMenuItem(node, item, true)" />
                        </span>
                    </template>
                </span>
            </span>
        </span>
        <ideo-tree-courses-program-nodes :pages="pages" :user-program="true" :expanded="expanded" :parent="node" :menu="menu" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" @restore="onRestore" @resetUnit="resetUnitResult" class="tree tree-nested" />
    </li>
</template>

<style scoped>
.text-default {
    color: #cdd1d9 !important;
}
</style>
