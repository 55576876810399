<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { FormType } from '@/helpers/Form';

const model = defineModel<string>();
const props = defineProps({
  "name": null,
  "label": null,
  "placeholder": { default: null },
  "index": { default: 0 },
  "visible": { type: Boolean, default: true },
  "value": { default: undefined },
  "display": { type: [Function, String], default: undefined },
  "menuClass": { default: 'p-0' },
  "dropdown": { type: Boolean, default: true },
  "autoApply": { type: Boolean, default: true }
});
const emit = defineEmits(["clear", "click", "open", "close", "reset"]);

const filter = inject<FormType<any>>('filter');
const dirty = inject<Ref<string[]>>('dirty');
const reset = inject<(name?: string) => void>('reset');
const apply = inject<() => void>('apply');

// eslint-disable-next-line vue/no-dupe-keys
const display = computed(() =>
{
    return model.value;
});

function onReset()
{
    model.value = '';
    emit('reset');
}
</script>

<template>
    <ideo-filter
        :value="model"
        :display="display"
        :name="props.name"
        :label="props.label"
        :index="props.index"
        :visible="props.visible"
        :menu-class="props.menuClass"
        :dropdown="props.dropdown"
        :auto-apply="props.autoApply"
        @clear="emit('clear', props.name)"
        @click="emit('click')"
        @open="emit('open')"
        @close="emit('close')"
    >
        <slot name="header"></slot>
        <ideo-input-group>
            <ideo-form-input v-model="model" type="text" :name="props.name" :placeholder="props.placeholder" class="bg-body py-3 border-0" v-focus @keydown.enter.prevent></ideo-form-input>
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="onReset"></ideo-button>
            </template>
        </ideo-input-group>
        <slot name="footer"></slot>
    </ideo-filter>
</template>
