<script setup lang="ts">
import { TreeNode } from '../utils';

defineOptions({
    name: 'ideo-tree'
});

defineProps({
  "pages": null,
  "expanded": null,
  "menu": { type: Function, default: null },
  "active": { type: Function, default: null },
  "move": { type: Function, default: () => false },
  "iconVisible": { type: Boolean, default: false },
  "actionVisible": { type: Boolean, default: false }
});

const emit = defineEmits(["toggle", "click", "sort"]);

const onToggle = (node: TreeNode): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNode): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNode[]): void =>
{
    emit('sort', pages);
};
</script>

<template>
    <ideo-tree-nodes :pages="pages" :expanded="expanded" :parent="null" :menu="menu" :active="active" :move="move" :icon-visible="iconVisible" :action-visible="actionVisible" @toggle="onToggle" @click="onClick" @sort="onSort" class="tree">
        <template #toggle="{node}">
            <slot name="toggle" :node="node"></slot>
        </template>
        <template #icon="{node}">
            <slot name="icon" :node="node"></slot>
        </template>
        <template #default="{node}">
            <slot name="default" :node="node"></slot>
        </template>
    </ideo-tree-nodes>
</template>

<style lang="scss">
[data-bs-theme=dark] .tree {
    li {
        > .active {
            background: var(--bs-gray-500);
        }

        > span {
            a, i {
                color: var(--bs-white);
            }
        }
    }
}
.tree {
    padding-left: 0;
    list-style: none;

    ul {
        padding-left: 1.5em;
        list-style: none;
    }

    li {
        position: relative;
        margin: 0.25rem 0;


        > .active {
            background: var(--bs-primary-bg-subtle);
            color: var(--bs-gray-800);

            a {
                color: var(--bs-gray-800);
            }

            i {
                color: var(--bs-gray-800);
            }

            .text-success{
                + a{
                    color: var(--bs-link-color);
                }
            }
        }

        .muted {
            // opacity: 0.5;
            i, a, span {
                color: #b5b5b5;
            }
        }

        > span {
            // height: 28px;
            border-radius: 5px;

            a {
                margin-left: 0.625rem;
                color: var(--bs-body-color);
                padding: 0.25rem 0;
            }

            i {
                font-size: 12px;
                color: var(--bs-body-color);
                min-width: 5px;

                &.toggle {
                    width: 16px;
                    font-size: 8px;
                    padding: 5px 0;
                    cursor: pointer;
                }
            }
        }

        > .popover > .popover-body {
            min-width: 150px;
            // padding: 0 !important;
        }
    }
}
</style>
