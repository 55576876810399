<template>
    <ideo-form-group :label="$t('[[[Data publikacji]]]')">
        <ideo-datetime v-model="form.settings.datePublication" name="datePublication" type="datetime" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Data wygaśnięcia]]]')">
        <ideo-datetime v-model="form.settings.dateExpiration" name="dateExpiration" type="datetime" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Barwa tekstu]]]')" :invalid-feedback="form.$errors.first('settings.textColor')" :state="form.$errors.state('settings.textColor')">
        <ideo-form-input v-model="form.settings.textColor" type="color" name="settings.textColor"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Barwa tła]]]')" :invalid-feedback="form.$errors.first('settings.backgroundColor')" :state="form.$errors.state('settings.backgroundColor')">
        <ideo-form-input v-model="form.settings.backgroundColor" type="color" name="settings.backgroundColor"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('settings.content')" :state="form.$errors.state('settings.content')">
        <editor id="editorContent" v-model="form.settings.content"></editor>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';

@Options({
    name: 'widget-infobar-settings'
})
export default class InfobarSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        content: '',
        textColor: '',
        backgroundColor: '',
        datePublication: null,
        dateExpiration: null
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }
}
</script>
