/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'admin',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Pamięć podręczna
            {
                path: 'monitoring/cache',
                name: 'elearning-cache',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/cache/views/MainForm.vue')
            },
        ]
    },
    {
        path: 'materials',
        name: 'elearning-materials',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'categories',
                name: 'elearning-materials-categories',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/materials/views/CategoriesList.vue'),
            },
            {
                path: 'categories/:id',
                name: 'elearning-materials-categories-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/materials/views/CategoryForm.vue')
            },
            {
                path: 'categories/add',
                name: 'elearning-materials-categories-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/materials/views/CategoryForm.vue')
            },
            {
                path: 'materials/list/:categoryId?',
                name: 'elearning-materials-materials-list',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/materials/views/MaterialsList.vue')
            },
            {
                path: 'materials/:id',
                name: 'elearning-materials-materials-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.ElMaterial" },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/materials/views/MaterialForm.vue')
            },
            {
                path: 'materials/add',
                name: 'elearning-materials-materials-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/materials/views/MaterialForm.vue')
            },
            {
                path: 'materials/import',
                name: 'elearning-materials-materials-import',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/materials/views/MaterialsImport.vue')
            }
        ]
    },
    {
        path: 'courses',
        name: 'elearning-courses',
        meta: { auth: true },
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Kategorie kursów
            {
                path: 'categories',
                name: 'elearning-courses-categories',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/categories/CategoriesList.vue'),
            },
            {
                path: 'categories/:id',
                name: 'elearning-courses-categories-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/categories/CategoryForm.vue')
            },
            {
                path: 'categories/add',
                name: 'elearning-courses-categories-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/categories/CategoryForm.vue')
            },
            // Kursy
            {
                path: 'courses',
                name: 'elearning-courses-courses',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/courses/CoursesList.vue')
            },
            {
                path: 'courses/:id',
                name: 'elearning-courses-courses-edit',
                meta: { auth: true, model: "Ideo.Core.Data.Model.ElCourse" },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/courses/CourseForm.vue')
            },
            {
                path: 'courses/add/:mode?',
                name: 'elearning-courses-courses-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/courses/CourseForm.vue')
            },
            {
                path: 'lessons/add/:courseId?',
                name: 'elearning-courses-lessons-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/units/LessonForm.vue')
            },
            {
                path: 'lessons/:id?',
                name: 'elearning-courses-lessons-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/units/LessonForm.vue')
            },
            {
                path: 'tests/add/:courseId?',
                name: 'elearning-courses-tests-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/units/TestForm.vue')
            },
            {
                path: 'tests/:id?',
                name: 'elearning-courses-tests-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courses/views/units/TestForm.vue')
            }
        ]
    },
    {
        path: 'courses-creator',
        name: 'elearning-courses-creator',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/coursesCreator/views/CoursesCreator.vue')
    },
    {
        path: 'scorm',
        name: 'elearning-scorm',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'scormpackages',
                name: 'elearning-scorm-scormpackages',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/scorm/views/scormpackages/ScormPackagesList.vue')
            },
            {
                path: 'scormpackages/:id',
                name: 'elearning-scorm-scormpackages-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/scorm/views/scormpackages/ScormPackageForm.vue')
            },
            {
                path: 'scormpackages/add',
                name: 'elearning-scorm-scormpackages-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/scorm/views/scormpackages/ScormPackageForm.vue')
            },
            {
                path: 'scormpackages/debug',
                name: 'elearning-scorm-scormdebug',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/scorm/views/scormdebuglogs/ScormDebugLogList.vue')
            }
        ]
    },
    {
        path: 'certificates',
        name: 'elearning-certificates',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'certificates',
                name: 'elearning-certificates-certificates',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/certificates/views/certificates/CertificatesList.vue')
            },
            {
                path: 'certificates/:id',
                name: 'elearning-certificates-certificates-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/certificates/views/certificates/CertificateForm.vue')
            },
            {
                path: 'certificates/add',
                name: 'elearning-certificates-certificates-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/certificates/views/certificates/CertificateForm.vue')
            },
            {
                path: 'certificates/dashboard/:id',
                name: 'elearning-certificates-certificates-dashboard',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/certificates/views/certificates/CertificateDashboard.vue')
            }
        ]
    },
    {
        path: 'elearning-reports',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'elearning-reports',
                name: 'elearning-reports',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courseReports/views/CoursesList.vue'),
            },
            {
                path: 'elearning-reports/:id,:name',
                name: 'elearning-reports-details',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/courseReports/views/CourseDetails.vue')
            }
        ]
    },
    {
        path: 'learningPath',
        name: 'elearning-learningPath',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            // Ścieżki szkoleniowe
            {
                path: 'learningPaths',
                name: 'elearning-learningPath-learningPaths',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/learningPath/views/LearningPathsList.vue')
            },
            {
                path: 'learningPaths/add',
                name: 'elearning-learningPath-learningPaths-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/learningPath/views/LearningPathForm.vue')
            },
            {
                path: 'learningPaths/:id',
                name: 'elearning-learningPath-learningPaths-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/learningPath/views/LearningPathForm.vue')
            }
        ]
    },
    {
        path: 'coordinatorProfile',
        name: 'elearning-coordinatorProfile',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'coordinatorProfiles',
                name: 'elearning-coordinatorProfile-coordinatorProfiles',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/coordinatorProfiles/views/CoordinatorProfileList.vue')
            }
        ]
    },
    {
        path: 'events',
        name: 'elearning-event',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'events',
                name: 'elearning-events',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/events/views/EventsList.vue')
            },
            {
                path: 'events/add',
                name: 'elearning-events-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/events/views/EventForm.vue')
            },
            {
                path: 'events/:id',
                name: 'elearning-events-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/events/views/EventForm.vue')
            },
            {
                path: 'categories',
                name: 'elearning-events-categories',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/events/views/categories/CategoriesList.vue')
            },
            {
                path: 'categories/add',
                name: 'elearning-events-categories-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/events/views/categories/CategoryForm.vue')
            },
            {
                path: 'categories/:id',
                name: 'elearning-events-categories-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/events/views/categories/CategoryForm.vue')
            }
        ]
    },
    {
        path: 'gamification',
        name: 'elearning-gamification',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'elearning-gamification-list',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/gamification/views/List.vue')
            },
            {
                path: 'points',
                name: 'elearning-gamification-points',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/gamification/views/Points.vue')
            },
            {
                path: 'badges',
                name: 'elearning-gamification-badges',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/gamification/views/Badges.vue')
            },
            {
                path: 'badges/add',
                name: 'elearning-gamification-badges-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/gamification/views/BadgeForm.vue')
            },
            {
                path: 'badges/:id',
                name: 'elearning-gamification-badges-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "elearning" */ '@/modules/elearning/gamification/views/BadgeForm.vue')
            },
        ]
    }
];
