<template>
    <div class="text-white bg-primary-gradient box border-radius-6">
        <div class="box-pads tile">
            <div class="icon-tile">
                <i class="fa-solid fa-star"></i>
            </div>
            <div class="count-tile-description">
                <div class="count-tile-numbers">
                    {{ countCoordnator.count }}
                </div>
                {{ $t('[[[Koordynatorów]]]') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import DashboardService, { CountModel } from '../../services/DashboardService';
import { useHomeStore } from '../../store/index';

@Options({
    name: 'coordinators',
})
export default class Coordinators extends Vue
{
    public store = useHomeStore();
    public countCoordnator: CountModel = { count: 0 };

    public async mounted(): Promise<void>
    {
        await this.loadData();
    }

    public async loadData(): Promise<void>
    {
        try
        {
            this.countCoordnator = await DashboardService.getActiveCoordinatorsCount();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
        }
    }
}
</script>

<style scoped lang="scss">
.border-radius-6 {
    border-radius: 6px;
}

.count-tile-description {
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 787px) and (max-width: 1550px) {
        font-size: .8125rem;
        align-items: center;
    }

    &.course-info {
        flex-direction: row;
        align-items: center;
        gap: .5rem;
    }
}

.count-tile-numbers {
    font-size: 34px;
    line-height: 1;
}

.tile {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.box-pads {
    padding: 25px 15px;

    @media screen and (min-width: 787px) and (max-width: 1550px) {
        padding: 8px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
    }
}

.tile-box {
    display: flex;
    align-items: center;
    font-size: 17px;
}

.icon-tile {
    font-size: 26px;
    color: #fff;
}

.box {
    height: 100%;
    display: flex;
}
</style>
