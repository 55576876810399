import { Plugin } from 'vue';
import { kebabCase } from 'lodash';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useComponentsStore = defineStore('cms-components', () =>
{
    const modules = reactive<string[]>([]);
    const widgets = reactive<string[]>([]);

    return { modules, widgets };
});

export interface ValidatableComponent
{
    validate(): Promise<void>
}

const EditoPlugin: Plugin =
{
    async install(app, options)
    {
        const store = useComponentsStore();

        // Custom components
        app.component('EditoMetaSettings', (await import('@/components/cms/MetaSettings.vue')).default);
        app.component('EditoWebsiteSelector', (await import('@/components/cms/WebsiteSelector.vue')).default);
        app.component('EditoPageSelector', (await import('@/components/cms/PageSelector.vue')).default);
        app.component('EditoPagePublications', (await import('@/components/cms/PagePublications.vue')).default);
        app.component('EditoPreview', (await import('@/components/cms/Preview.vue')).default);
        app.component('EditoPreviewButton', (await import('@/components/cms/PreviewButton.vue')).default);
        app.component('EditoTags', (await import('@/components/cms/Tags.vue')).default);
        app.component('EditoPageFilter', (await import('@/components/cms/EditoPageFilter.vue')).default);
        app.component('EditoWebsiteFilter', (await import('@/components/cms/EditoWebsiteFilter.vue')).default);
        app.component('EditoArticleCategories', (await import('@/components/cms/ArticleCategories.vue')).default);
        app.component('EditoSubscriptionsCategories', (await import('@/components/cms/SubscriptionsCategories.vue')).default);

        // Module settings
        const requireModuleComponent = import.meta.glob('@/modules/cms/**/views/*Settings.vue', { eager: true });

        Object.values(requireModuleComponent).forEach((component: any) =>
        {
            store.modules.push(kebabCase(component.default.name));
            app.component(component.default.name, component.default);
        });

        // Widget settings
        const requireWidgetComponent = import.meta.glob('@/modules/cms/**/widgets/**/*Settings.vue', { eager: true });

        Object.values(requireWidgetComponent).forEach((component: any) =>
        {
            store.widgets.push(kebabCase(component.default.name));
            app.component(component.default.name, component.default);
        });
    }
};

export default EditoPlugin;
