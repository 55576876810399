import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { Columns } from '@/helpers/Interfaces';
import { FilterModel } from '@/modules/core/users/services/UsersService';

export const useUsersStore = defineStore('core-users', () =>
{
    const columns = reactive<Columns>({
        visible: {},
        positions: {}
    });
    const filter = reactive(Form.create<FilterModel>({
        userName: '',
        givenName: '',
        surname: '',
        unitId: null,
        unitsBelow: true,
        roleId: null,
        dateFromUtc: null,
        dateDueUtc: null,
        range: null,
        tab: 'account',
        teamId: null,
        emailConfirmed: null,
        isActive: null,
        publicRegistration: null,
        accountType: null
    }));
    const pager = reactive(new Pager(1, 20, 'Surname', 'ASC'));
    const maxPageSize = ref<number>(0);
    const checkAll = ref<boolean>(false);

    return { columns, filter, pager, checkAll, maxPageSize };
});
