<script lang="ts" setup>
import { ref, reactive, computed, watch } from 'vue';
import { FormType } from '@/helpers/Form';
import { EntityVersion, Pagination, Resource } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import { handleException } from '@/helpers/Utils';
import { PublicationEnum } from '@/helpers/Enums';
import EntityVersions from '@/components/common/EntityVersions.vue';
import PublicationStatus from '@/components/common/PublicationStatus.vue';

const props = defineProps({
  "versions": { type: Function,  },
  "fetch": { type: Function,  },
  "status": { type: Function,  },
  "select": { type: Function,  },
  "form": null,
  "canEdit": { type: Boolean,  },
  "canAccept": { type: Boolean,  }
});
const form = computed(() => props.form);
const emit = defineEmits(["compare", "select"]);

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();

const loaded = ref(false);
const id = computed(() => form.value.id);
const pager = reactive(new Pager(1, 10, 'Id', 'DESC')) as Pager;
const items = ref<EntityVersion[]>([]);

watch(id, async (value) =>
{
    if (value > 0 && !items.value.find(p => p.id == value))
    {
        await loadVersions();
        loaded.value = true;
    }
});

watch(() => ({ id: form.value.id, status: form.value.status }), async (value, old) =>
{
    if (loaded.value && value.id == old.id && value.status != old.status)
    {
        await loadVersions();
    }
});

async function loadVersions(more: boolean = false)
{
    if (!id.value) return;

    try
    {
        if (more == true)
            pager.pageIndex++;
        else
            pager.loading = true;

        const response = await props.versions(id.value, pager);

        items.value.push(...response.items);
        pager.setTotalRows(response.totalRows);
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
    finally
    {
        pager.loading = false;
    }
}

async function selectVersion(id: number)
{
    emit('select', id);
}

async function compareVersion(id: number)
{
    if (!id) return;

    try
    {
        const result = await props.fetch(id);

        emit('compare', result);
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function changeStatus(status: PublicationEnum)
{
    try
    {
        await props.status(id.value, status);

        form.value.status = status;

        items.value
            .filter(p => p.id == id.value)
            .forEach(p => { p.status = status; });

        if (status == PublicationEnum.Accepted)
        {
            items.value
                .filter(p => p.id != id.value && p.status == PublicationEnum.Accepted)
                .forEach(p => { p.status = PublicationEnum.Finished; });
        }

        $alert.success($t('[[[Status dokumentu został zmieniony.]]]'));
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}
</script>

<template>
    <PublicationStatus
        v-model="form.status"
        :can-edit="props.canEdit"
        :can-accept="props.canAccept"
        :disabled="form.id == 0"
        @change-status="changeStatus"
    />
    <EntityVersions
        :value="items"
        :current="form.id"
        :pager="pager"
        @select="selectVersion"
        @compare="compareVersion"
        @more="loadVersions(true)"
    />
</template>
