<template>
    <div class="alert alert-warning text-center" v-if="disabled">
        {{ $t('[[[Nie możesz modyfikować uprawnień]]]') }}
    </div>
    <div v-else>
        <div class="mb-2">
            <multiselect
                v-model="owner" :options="options" :loading="loading" label="value" :placeholder="$t('[[[zacznij pisać aby wyszukać użytkownika...]]]')"
                group-label="group" group-values="values" :group-select="false" @search-change="onSearchChanged" @update:modelValue="onChange"
                :select-label="' '" :selected-label="' '" :deselect-label="' '" :multiple="false" :searchable="true" :max-height="300"
                :internal-search="false" :clear-on-select="true" :close-on-select="true" :show-no-results="true" :hide-selected="false"
                :disabled="id == 0"
            >
                <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
                <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
            </multiselect>
        </div>
        <div class="text-end mb-2">
            <ideo-button variant="success" :disabled="!owner" @click="openModal(ownerType, owner.key, owner.value)">
                {{ $t('[[[Dodaj]]]') }}
            </ideo-button>
        </div>
        <div class="list-group">
            <div
                v-for="owner in owners" :key="`${owner.ownerType}.${owner.ownerId}`"
                class="list-group-item list-group-item-action p-2 d-flex pointer"
                @click="editPermissions(owner)"
            >
                <div class="d-flex align-items-start pe-2 me-2 border-end">
                    <i class="fad fa-fw fa-2x" :class="typeIcon(owner)" :title="typeName(owner.ownerType)"></i>
                </div>
                <div class="flex-fill ps-1">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill fw-medium">{{ owner.ownerName }}</div>
                        <ideo-button variant="light" size="sm" pill :title="$t('[[[Edytuj uprawnienia]]]')" @click.stop.prevent="editPermissions(owner)">
                            <i class="far fa-pencil"></i>
                        </ideo-button>
                        <ideo-button class="me-0" variant="light" size="sm" pill :title="$t('[[[Usuń]]]')" :id="`btn-delete-${owner.ownerId}`" @click.stop.prevent>
                            <i class="far fa-xmark"></i>
                        </ideo-button>
                        <confirmation placement="bottomleft" :message="$t('[[[Potwierdzenie usunięcia]]]')" :target="`btn-delete-${owner.ownerId}`" :value="owner.ownerId" @confirm="onDelete(owner)" />
                    </div>
                    <div class="mt-1">
                        <div class="d-flex align-items-center small" v-for="([permission, status], j) in Object.entries(owner.permissions)" :key="j">
                            <i class="fas fa-fw fa-check text-success" :title="$t('[[[Dostęp przyznany]]]')" v-if="status == permissionsState.Allow"></i>
                            <i class="fas fa-fw fa-times text-danger" :title="$t('[[[Dostęp zabroniony]]]')" v-if="status == permissionsState.Deny"></i>
                            <span class="ms-1">{{ permissionName(permission) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-light text-center" v-if="owners.length == 0">
                {{ $t('[[[Nikomu nie przydzielono uprawnień]]]') }}
            </div>
        </div>

        <!-- Modal -->
        <ideo-modal ref="modal" static title="ad">
            <template #modal-header>
                <h5 class="modal-title">
                    {{ form.text }} <small class="text-muted">{{ typeName(form.ownerType) }}</small>
                </h5>
                <div class="d-flex">
                    <div class="btn-group btn-group-sm rounded overflow-hidden">
                        <label class="btn btn-secondary mb-0" @click="checkAll(permissionsState.Deny)">
                            <i class="fas fa-fw fa-times" :title="$t('[[[Zabierz dostęp]]]')"></i>
                        </label>
                        <label class="btn btn-secondary mb-0" @click="checkAll(null)">
                            <i class="fas fa-fw fa-minus" :title="$t('[[[Dostęp niezdefiniowany]]]')"></i>
                        </label>
                        <label class="btn btn-secondary mb-0" @click="checkAll(permissionsState.Allow)">
                            <i class="fas fa-fw fa-check" :title="$t('[[[Przyznaj dostęp]]]')"></i>
                        </label>
                    </div>
                </div>
            </template>
            <div class="mb-2" v-for="(section, i) in group.sections" :key="i">
                <hr class="mt-0 mb-1" v-if="i > 0">
                <div class="d-flex pt-1" v-for="(permission, j) in section" :key="j">
                    <div class="pt-1">
                        {{ permission.value.alt }}
                    </div>
                    <div class="ms-auto">
                        <div class="btn-group btn-group-sm border border-secondary rounded overflow-hidden">
                            <label class="btn mb-0" :class="[checked(permission.key, permissionsState.Deny) ? 'btn-secondary' : 'btn-light']" @click="check(permission.key, permissionsState.Deny)">
                                <i class="fas fa-fw fa-times" :title="$t('[[[Zabierz dostęp]]]')"></i>
                            </label>
                            <label class="btn mb-0" :class="[checked(permission.key, null) ? 'btn-secondary' : 'btn-light']" @click="check(permission.key, null)">
                                <i class="fas fa-fw fa-minus" :title="$t('[[[Dostęp niezdefiniowany]]]')"></i>
                            </label>
                            <label class="btn mb-0" :class="[checked(permission.key, permissionsState.Allow) ? 'btn-secondary' : 'btn-light']" @click="check(permission.key, permissionsState.Allow)">
                                <i class="fas fa-fw fa-check" :title="$t('[[[Przyznaj dostęp]]]')"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok, cancel }">
                <ideo-button variant="light" @click="cancel()">
                    <i class="fa fa-ban"></i>
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
                <ideo-button variant="success" @click="savePermissions(); ok();" :disabled="!form.active()">
                    <i class="fas fa-check"></i>
                    {{ $t('[[[Zapisz]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Debounce, Prop, Ref, Watch } from '@/helpers/Decorators';
import { KeyValue } from '@/helpers/Interfaces';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';
import PermissionsService, { PersonasGroup, PermissionsGroup, PermissionStateEnum, ResourceOwner } from '@/modules/core/common/services/PermissionsService';
import {Form, FormType} from '@/helpers/Form';
import { cloneDeep } from 'lodash';

interface PermissionsModel
{
    text: string,
    ownerId: number,
    ownerType: string,
    resourceId: number,
    resourceType: string,
    permissionsType: string,
    permissions: Record<string, PermissionStateEnum>
}

@Options({
    name: 'resource-permissions',
    components: {
        'multiselect': Multiselect
    }
})
export default class ResourcePermissions extends Vue
{
    @Prop()
    public resource!: string;

    @Prop()
    public id!: number;

    @Prop({ default: false })
    public disabled!: boolean;

    @Ref('modal')
    public modal: () => IdeoModal;

    public loading: boolean = false;
    public options: PersonasGroup[] = [];
    public owner: KeyValue<number, string> = null;
    public ownerType: string = null;
    public group: PermissionsGroup = null;
    public owners: ResourceOwner[] = [];

    public form: FormType<PermissionsModel> = Form.create<PermissionsModel>({
        text: '',
        ownerId: 0,
        ownerType: '',
        resourceId: 0,
        resourceType: '',
        permissionsType: '',
        permissions: {}
    });

    public get permissionsState(): typeof PermissionStateEnum
    {
        return PermissionStateEnum;
    }

    public async loadPermissions(): Promise<void>
    {
        try
        {
            if (this.group == null)
            {
                this.group = await PermissionsService.resourcePermissions(this.resource);
            }
        }
        catch (ex)
        {
            this.owners = [];
        }
    }

    public async loadData(): Promise<void>
    {
        try
        {
            this.owners = await PermissionsService.resourceOwners(this.resource, this.id);
        }
        catch (ex)
        {
            this.owners = [];
        }
    }

    @Watch('id', { immediate: true })
    public async onIdChanged(id: number): Promise<void>
    {
        if (id > 0 && !this.disabled)
        {
            await this.loadPermissions();
            await this.loadData();
        }
    }

    @Debounce(500)
    public async onSearchChanged(query: string): Promise<void>
    {
        if (query)
        {
            this.loading = true;
            this.searchOptions(query);
            this.loading = false;
        }
    }

    public async searchOptions(query: string): Promise<void>
    {
        this.options = await PermissionsService.getIdentities(query);
    }

    public onChange(value: KeyValue<number, string>): void
    {
        this.ownerType = this.options.find(p => p.values.includes(value))?.type;
    }

    public typeName(type: string): string
    {
        if (type.endsWith('User')) return this.$t('[[[Użytkownik]]]');
        else if (type.endsWith('Role')) return this.$t('[[[Rola]]]');
        else if (type.endsWith('Team')) return this.$t('[[[Zespół]]]');
        else 'n/a';
    }

    public typeIcon(owner: ResourceOwner): string
    {
        if (owner.ownerType.endsWith('User')) return 'fa-user text-primary';
        else if (owner.ownerType.endsWith('Role')) return 'fa-users text-success';
        else if (owner.ownerType.endsWith('Team')) return 'fa-user-friends text-info';
        else return '';
    }

    public permissionName(permissions: string): string
    {
        return this.group.sections.flat().find(p => p.key == permissions)?.value.alt ?? permissions;
    }

    public openModal(ownerType: string, ownerId: number, text: string, permissions: Record<string, PermissionStateEnum> = {}): void
    {
        this.form.text = text;
        this.form.ownerId = ownerId;
        this.form.ownerType = ownerType;
        this.form.resourceId = this.id;
        this.form.resourceType = this.resource;
        this.form.permissionsType = this.group.key;
        this.form.permissions = cloneDeep(permissions);
        this.modal().show();
    }

    public editPermissions(owner: ResourceOwner): void
    {
        this.openModal(owner.ownerType, owner.ownerId, owner.ownerName, owner.permissions);
    }

    public check(value: string, mode: PermissionStateEnum): void
    {
        if (mode == null && this.form.permissions.hasOwnProperty(value))
        {
            delete this.form.permissions[value];
        }

        if (mode != null)
        {
            this.form.permissions = {
                ...this.form.permissions,
                [value]: mode
            };
        }
    }

    public checkAll(mode: PermissionStateEnum): void
    {
        if (mode == null)
        {
            this.form.permissions = {};
        }
        else
        {
            const keys = this.group.sections.flatMap((section) => section.map(el => el.key));

            const newPermissions: any = {};

            keys.forEach((key) =>
            {
                newPermissions[key] = mode === PermissionStateEnum.Allow ? 'Allow' : 'Deny';
            });

            this.form.permissions = newPermissions;
        }
    }

    public checked(value: string, mode: PermissionStateEnum): boolean
    {
        return mode != null
            ? this.form.permissions.hasOwnProperty(value) === true && this.form.permissions[value] == mode
            : this.form.permissions.hasOwnProperty(value) === false;
    }

    public async savePermissions(): Promise<void>
    {
        try
        {
            this.form.wait();

            const keys = this.group.sections.selectMany(p => p).select(p => p.key).toArray();

            for (const key of Object.keys(this.form.permissions))
            {
                if (!keys.includes(key))
                {
                    delete this.form.permissions[key];
                }
            }

            const data = this.form.only(['ownerId', 'ownerType', 'permissionsType', 'permissions']);

            await PermissionsService.saveResourcePermissions(this.resource, this.id, data);
            await this.loadData();

            this.$emit('savePermissions');
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors)
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public async onDelete(owner: ResourceOwner): Promise<void>
    {
        try
        {
            const data = {
                ownerId: owner.ownerId,
                ownerType: owner.ownerType,
                permissions: {},
                permissionsType: this.group.key
            };

            await PermissionsService.saveResourcePermissions(this.resource, this.id, data);
            this.loadData();

            this.$emit('savePermissions');

            this.$alert.success(this.$t('[[[Uprawnienia zostały usunięte.]]]'));
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors)
            });
        }
    }
}
</script>
