<template>
    <ideo-form-group>
        <ideo-form-checkbox v-model="form.settings.nameIsVisible">
            {{ $t('[[[Wyświetlaj nazwę bloczka]]]') }}
        </ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Wybór listy stron]]]')" :invalid-feedback="form.$errors.first('settings.pages')" :state="form.$errors.state('settings.pages')" required>
        <multiselect
            v-model="form.settings.pages"
            label="text"
            track-by="value"
            :options="selectedPageOptions"
            :multiple="true"
            :placeholder="$t('[[[Wybierz strony]]]')"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        </multiselect>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Liczba wyświetlanych najnowszych elementów]]]')" :invalid-feedback="form.$errors.first('settings.display')" :state="form.$errors.state('settings.display')">
        <ideo-form-select v-model="form.settings.display" :options="displayOptions"></ideo-form-select>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { CurrentWebsite, Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import { $t } from '@/plugins/localization';
import { wait } from '@/helpers/Utils';
import PagesService, { PageListItemModel } from '@/modules/cms/pages/services/PagesService';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';
import * as yup from 'yup';
import { useWebsitesStore } from '@/modules/cms/websites/store';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-link-settings',
    components: {
        'multiselect': Multiselect
    }
})
export default class LinkSettings extends Vue
{
    @Prop() public modelValue!: any;

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public module: string = 'link';
    public defaults: any = {
        pages: [],
        nameIsVisible: true,
        display: '10'
    };

    public pageOptions: PageListItemModel[] = [];

    public get displayOptions(): Option[]
    {
        return [
            { value: '3', text: $t('[[[3]]]') },
            { value: '5', text: $t('[[[5]]]') },
            { value: '10', text: $t('[[[10]]]') }
        ];
    }

    public get current(): CurrentWebsite
    {
        return useWebsitesStore().current;
    }

    public get selectedPageOptions(): Option[]
    {
        return this.pageOptions.map(p =>
        {
            return { value: p.id.toString(), text: p.option };
        });
    }

    public async created(): Promise<void>
    {
        await wait(() => this.current.website);
        await this.loadPages();
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async loadPages(): Promise<void>
    {
        try
        {
            this.pageOptions = await PagesService.getPagesByModule(this.current.website, this.current.locale, this.module);
            this.pageOptions = this.pageOptions.filter(x => x.id != 0);
        }
        catch (ex)
        {
            this.pageOptions = [];
            this.$log.debug(ex);
        }
    }

    public async validate(): Promise<void>
    {
        this.form.validate(schema => ({
            settings: schema.object({
                pages: yup.array().min(1, this.$t('[[[Wybranie strony jest wymagane]]]'))
            })
        }));
    }
}
</script>
