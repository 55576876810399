<template>
    <div class="select-container">
        <select
            v-model="selectValue"
            :id="id"
            ref="select"
            class="form-select"
            :multiple="props.multiple"
            :disabled="props.disabled"
        >
            <slot name="first"></slot>
            <slot name="default">
                <ideo-form-select-option v-for="(option, i) in options" :value="optionValue(option)" :key="i" :disabled="isDisabled(option)">
                    {{ option[textField] }}
                </ideo-form-select-option>
            </slot>
        </select>

        <div class="custom-select" :class="{'custom-multiselect': props.multiple || props.size > 1}" ref="customSelect">
            <div class="input-wrap">
                <ideo-form-input v-model="query" type="text" control-size="1" @input="handleInputQuery" @blur="onBlurQuery" :disabled="props.disabled" />
                <i v-if="props.multiple || props.size > 1" class="select-icon fa-solid fa-magnifying-glass" @click="focusOnQuery" />
                <i v-else class="select-icon fas fa-chevron-down" @click="focusOnQuery" />
            </div>

            <div :class="['options', 'accordion', 'scroll', {'hide': !filteredOptionsTree?.length, 'multiple': props.multiple || props.size > 1}]" ref="popOver">
                <ul>
                    <li
                        v-for="(option, i) in filteredOptionsTree"
                        :key="i"
                        :class="{'disabled': option.disabled, 'selected': props.multiple ? (option as OptionElement).selected : (option as OptionElement).value === selectValue}"
                        :tabindex="((option as OptionGroupElement).children || option.disabled) ? -1 : 0"
                        @click.exact="handleCustomOptionClick(option)"
                        @keyup.enter="handleCustomOptionClick(option)"
                        @click.ctrl="handleCustomOptionClick(option)"
                    >
                        <template v-if="(option as OptionGroupElement).children">
                            <label>{{ (option as OptionGroupElement).label }}</label>
                            <ul>
                                <li
                                    v-for="(child, j) in (option as OptionGroupElement).children"
                                    :key="j"
                                    :class="{'disabled': child.disabled, 'selected': props.multiple ? child.selected : child.value === selectValue}"
                                    :tabindex="(option.disabled || child.disabled) ? -1 : 0"
                                    @click.exact.stop="handleCustomOptionClick(child)"
                                    @keyup.enter.stop="handleCustomOptionClick(child)"
                                    @click.ctrl="handleCustomOptionClick(child)"
                                >
                                    {{ child.text || '&nbsp;' }}
                                </li>
                            </ul>
                        </template>

                        <template v-else>
                            {{ (option as OptionElement).text || '&nbsp;' }}
                        </template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, nextTick, unref, watch } from 'vue';
import IdeoFormSelectOption from './IdeoFormSelectOption.vue';

interface OptionElement {
    value: string;
    text: string;
    label: string;
    disabled: boolean;
    selected: boolean;
}

interface OptionGroupElement {
    label: string;
    disabled: boolean;
    children: OptionElement[];
}

const emit = defineEmits(["change", "query"]);

const props = defineProps({
  "valueField": { default: 'value' },
  "textField": { default: 'text' },
  "disabledField": { default: 'disabled' },
  "options": { default: (): any[] => [] },
  "multiple": { type: Boolean, default: false },
  "disabled": { type: Boolean, default: false },
  "id": { default: undefined },
  "size": { default: 1 }
});

const optionsObserver = new MutationObserver(() => optionsTree.value = getSlottedOptions());

const selectValue = defineModel<any>({
    set(value: any)
    {
        emit('change', value);
        select.value.dispatchEvent(new CustomEvent('input', { bubbles: true }));
        select.value.blur();

        return value;
    }
});

watch(selectValue, (value) =>
{
    nextTick(() =>
    {
        for (const option of select.value.selectedOptions)
        {
            setSelectedQuery(option.text);
        }
    });
});

const select = ref<HTMLSelectElement>(null);
const query = ref('');
const tempQuery = ref('');
const customSelect = ref<HTMLInputElement | null>(null);
const popOver = ref<HTMLDivElement | null>(null);
const optionsTree = ref<(OptionElement | OptionGroupElement)[]>();
const blockSelectFilters = ref(false);

watch(query, (value) =>
{
    emit('query', value);
});


const filteredOptionsTree = computed(() =>
{
    return filterOptionsTreeByQuery();
});
const multipleMaxSize = computed(() => `${props.size > 1 ? props.size * 30 : 300}px`);

function handleInputQuery()
{
    blockSelectFilters.value = false;
}

function isDisabled(option: any)
{
    if ('disabledField' in option)
    {
        return option['disabledField'];
    }

    return false;
}

function optionValue(option: Record<string, any>)
{
    return option[props.valueField];
}

function setSelectedQuery(value: string)
{
    tempQuery.value = value;
    query.value = value;
    blockSelectFilters.value = true;
}

function createOptionElement(option: HTMLOptionElement, selected: any)
{
    if (selected === option.value) setSelectedQuery(option.text);

    const value = (option as HTMLOptionElement & { _value: any })._value; // used because only vue allows a value other than string in <option>

    return {
        value,
        selected: option.selected,
        text: option.text,
        disabled: option.disabled
    } as OptionElement;
}

function getSlottedOptions()
{
    const nodes = select.value.options as HTMLOptionsCollection;
    const selected = select.value.value;

    const grouped: (OptionElement | OptionGroupElement)[] = [];

    Array.from(nodes).forEach(option =>
    {
        if (option.parentElement.nodeName === 'OPTGROUP')
        {
            const optgroup = option.parentElement as HTMLOptGroupElement;
            let group = grouped.find(group => group.label === optgroup.label) as OptionGroupElement | undefined;

            if (!group)
            {
                group = {
                    label: optgroup.label,
                    disabled: optgroup.disabled,
                    children: []
                };

                grouped.push(group);
            }

            group.children.push(createOptionElement(option, selected));
        }
        else
        {
            grouped.push(createOptionElement(option as HTMLOptionElement, selected));
        }
    });

    return grouped;
}

function filterOptionsTreeByQuery()
{
    const queryValue = blockSelectFilters.value ? '' : query.value;

    return optionsTree.value?.reduce((acc, option) =>
    {
        if ('children' in option)
        {
            const children = option.children.filter(child => child.text?.toLowerCase().includes(queryValue?.toLowerCase()));

            if (children.length > 0)
            {
                acc.push({ ...option, children });
            }
        }
        else if (option.text?.toLowerCase().includes(queryValue?.toLowerCase()))
        {
            acc.push(option);
        }

        return acc;
    }, [] as (OptionElement | OptionGroupElement)[]);
}

function handleCustomOptionClick(option: OptionElement | OptionGroupElement)
{
    if (option.disabled) return;

    if ('value' in option)
    {
        if (!props.multiple)
        {
            selectValue.value = option.value;
            tempQuery.value = option.text;
            query.value = option.text;
            (document.activeElement as HTMLElement)?.blur();
            blockSelectFilters.value = true;
        }
        else
        {
            option.selected = !option.selected;

            selectValue.value = Array.isArray(selectValue.value) ? selectValue.value : [selectValue.value];

            selectValue.value = selectValue.value.includes(option.value)
                ? selectValue.value.filter((value: any) => value !== option.value)
                : [...selectValue.value, option.value];

            if (option.value === null)
            {
                selectValue.value = [option.value];
                optionsTree.value?.forEach(option =>
                {
                    if ('children' in option)
                    {
                        option.children.forEach(child => child.selected = false);
                    }
                    else
                    {
                        option.selected = false;
                    }
                });
            }
        }
    }
}

function calculateTotalOffsetTop(selectTemp: Element, scrollParent: Element)
{
    let totalOffsetTop = 0;

    while (selectTemp && selectTemp !== scrollParent)
    {
        totalOffsetTop += (selectTemp as HTMLElement).offsetTop;
        selectTemp = (selectTemp as HTMLElement).offsetParent;
    }

    return totalOffsetTop;
}

async function positionPopover()
{
    const popover = popOver.value;
    const select = customSelect.value;
    const selectRect = select.getBoundingClientRect();
    const scrollParent: HTMLDivElement | HTMLElement = select.closest('.scroll') || document.body;
    const scrollParentRect = scrollParent?.getBoundingClientRect();

    const spaceLeft = selectRect.left;
    const spaceRight = window.innerWidth - selectRect.right;
    const selectTotalOffsetTop = calculateTotalOffsetTop(select, scrollParent);

    const spaceBottom = scrollParent ? scrollParent.scrollHeight + scrollParentRect.top - (selectTotalOffsetTop + select.offsetHeight) : window.innerHeight - selectRect.bottom;
    const smallerSpace = Math.min(spaceLeft, spaceRight);
    const maxWidth = `calc(100vw - ${smallerSpace}px - 40px)`;
    const minWidth = `${selectRect.width}px`;

    if (spaceLeft > spaceRight)
    {
        popover.style.right = '0';
        popover.style.removeProperty('left');
    }
    else
    {
        popover.style.left = '0';
        popover.style.removeProperty('right');
    }

    popover.style.visibility = 'hidden';
    popover.style.display = 'block';

    await nextTick();

    const popoverHeight = popover.offsetHeight;


    popover.style.removeProperty('display');
    popover.style.removeProperty('visibility');

    if (spaceBottom < popoverHeight)
    {
        popover.style.top = '2px';
        popover.style.transform = 'translateY(-100%)';
    }
    else
    {
        popover.style.removeProperty('top');
        popover.style.removeProperty('transform');
    }

    popover.style.maxWidth = maxWidth;
    popover.style.minWidth = minWidth;
}

async function onBlurQuery(e: FocusEvent)
{
    if (!e.relatedTarget || (e.relatedTarget as Element).tagName !== 'LI') setSelectedQuery(tempQuery.value);
}

function focusOnQuery()
{
    customSelect.value?.querySelector('input')?.focus();
}

onMounted(async () =>
{
    optionsTree.value = getSlottedOptions();

    window.addEventListener('resize', positionPopover);
    optionsObserver.observe(select.value, { childList: true });

    positionPopover();
});

onUnmounted(() =>
{
    window.removeEventListener('resize', positionPopover);
    optionsObserver.disconnect();
});
</script>

<style lang="scss" scoped>
.select-container {
    position: relative;
    min-height: 33px;
    width: 100%;
}

.form-select {
    display: none;
    width: unset;
}

.input-wrap {
    position: relative;
}

.custom-select {
    position: relative;

    &:focus-within .options {
        display: block;
    }

    .select-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        translate: 0 -50%;
    }

    input {
        padding-right: 30px;
    }
}

.custom-multiselect {
    .input-wrap input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .options {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.options {
    display: none;
    position: absolute;
    background: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    z-index: 3; // editor scrollbar has z-index: 2, options were hidden by scrollbar
    min-width: 100%;
    max-height: v-bind(multipleMaxSize);
    overflow-y: auto;
    margin-top: -1px;
    box-shadow: var(--bs-box-shadow);
    border-color: var(--bs-accordion-btn-focus-border-color);
    border-radius: var(--bs-border-radius);

    &.multiple {
        display: block;
        border-color: var(--bs-border-color);
        box-shadow: none;
        position: relative;
        height: v-bind(multipleMaxSize);
    }

    &.hide:not(.multiple) {
        visibility: hidden;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        label {
            padding: 5px 10px;
            margin: 0;
            font-weight: 700;
        }

        li {
            padding: 5px 10px;

            &.disabled {
                pointer-events: none;
                filter: grayscale(1);
                opacity: 0.5;
            }

            &.selected, &.selected:hover {
                background-color: var(--ideo-select-option-checked-bg);
                color: var(--ideo-select-option-checked-color);
            }

            &:has(> label) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        li:not(:has(ul)) {
            cursor: pointer;

            &:hover {
                background-color: var(--bs-primary-bg-subtle);
            }
        }

        li:has(ul)
        {
            padding-left: 0;
            padding-right: 0;

            li {
                padding-left: 20px;
            }
        }

        ul {
            padding: 0;
            margin: 0;
        }
    }
}
</style>
