<template>
    <ideo-form-group :label="$t('[[[Ankieta]]]')" :invalid-feedback="form.$errors.first('settings.survey')" :state="form.$errors.state('settings.survey')" required>
        <ideo-autocomplete
            v-model="form.settings.survey"
            name="settings.survey"
            track-by="revisionOrigin"
            :placeholder="$t('[[[zacznij pisać aby wyszukać ankietę...]]]')"
            :fetch="(id: number) => fetchSurvey(id)"
            :search="(query: string, limit: number) => searchSurveys(query, limit)"
            :disabled="form.language === null"
        >
            <template #selected="{ option }">
                <div class="text-wrap">
                    {{ option.question }}
                </div>
            </template>
            <template #option="{ option }">
                <div class="text-wrap">
                    {{ option.question }}
                </div>
            </template>
        </ideo-autocomplete>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { FormType } from '@/helpers/Form';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import SurveysService, { ListItemModel } from '@/modules/cms/surveys/services/SurveysService';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-survey-settings'
})
export default class SurveySettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public module: string = 'surveys';
    public defaults: any = {
        page: null
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
        this.form.validate(schema => ({
            settings: schema.object({
                survey: schema.string().nullable().required(this.$t('[[[Wybranie ankiety jest wymagane]]]'))
            })
        }));
    }

    public async fetchSurvey(id: number): Promise<ListItemModel>
    {
        if (id != 0)
        {
            const response = await SurveysService.fetchAutocompleteModel(id);

            return response || null;
        }

        return null;
    }

    public async searchSurveys(query: string, limit: number): Promise<ListItemModel[]>
    {
        return await SurveysService.getAutocompleteList(query, limit);
    }
}
</script>
