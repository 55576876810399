import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource } from '@/helpers/Interfaces';

/**
 * SubscriptionsCategoriesService
 */
export default class SubscriptionsCategoriesService
{
    public static path = 'admin/cms/subscriptions/categories';

    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Resource<ListItemModel>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<ListItemModel>>
    {
        return (await axios.get<Pagination<ListItemModel>>(`${this.path}`, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @returns Promise<Resource<ListItemModel[]>>
     */
    public static async getAll(): Promise<Resource<ListItemModel[]>>
    {
        return (await axios.get<Resource<ListItemModel[]>>(`${this.path}/all`)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 20): Promise<ListItemModel[]>
    {
        const { data } = await axios.get<ListItemModel[]>(`${this.path}/autocomplete`, {
            params: {
                search: search,
                limit: limit
            }
        });

        return data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        const { data } = await axios.get<ListItemModel[]>(`${this.path}/autocomplete/${id}`);

        return data.first();
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`${this.path}/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<FormModel>
     */
    public static async create(model: FormModel): Promise<FormModel>
    {
        return (await axios.post<FormModel>(`${this.path}`, model)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`${this.path}/${model.id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`${this.path}/${id}`)).data as Statement;
    }


















}

export interface ListItemModel
{
    id: number;
    name: string;
    position: number;
}

export interface FilterModel
{
    name: string;
}

export interface FormModel
{
    id: number;
    name: string;
    position: number;
}
