import { axios } from '@/plugins/axios';
import { Statement, Pagination, Resource } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { merge } from 'lodash';
import Pager from '@/helpers/Pager';

/**
 * ArticleCategoriesService
 */
export default class ArticleCategoriesService
{
    public static path = 'admin/cms/articles/categories';

    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>(this.path, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`${this.path}/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(this.path, model)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(id: number, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`${this.path}/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`${this.path}/${id}`)).data as Statement;
    }

    /**
     * @returns Promise<ListItemModel>
     */
    public static async getAll(): Promise<Resource<ListItemModel[]>>
    {
        return (await axios.get<Resource<ListItemModel[]>>(`${this.path}/all`)).data;
    }

    /**
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getAllArticleCategoriesList(): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>(`${this.path}/sort`, {
            params: merge({})
        })).data;
    }

    /**
     * @param positions PagePosition[]
     *
     * @returns Promise<Statement>
     */
    public static async sortArticleCategories(positions: PositionModel[]): Promise<Statement>
    {
        return (await axios.put<Statement>(`${this.path}/sort/positions`, positions)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<string[]>
     */
    public static async getAutocomplete(search: string, limit: number = 10): Promise<string[]>
    {
        return (await axios.get<string[]>(`${this.path}/autocomplete/names`, {
            params: {
                search: search,
                limit: limit
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`${this.path}/autocomplete/${id}`)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 10): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`${this.path}/autocomplete`, {
            params: {
                search: search,
                limit: limit
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getSelectedFromWidget(id: number): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`${this.path}/widget/${id}`)).data;
    }
}

export interface ListItemModel
{
    id: number;
    name: string;
    articlesQty?: number;
    dateCreatedUtc?: DateTime;
    dateModifiedUtc?: DateTime;
    position?: number
}

export interface FormModel
{
    id: number;
    name: string;
}

export interface FilterModel
{
    name: string;
}

export interface PositionModel
{
    id: number;
    position: number;
}
