<template>
    <portal :to="`tabs-nav-${tabs.$.uid}`" v-if="isMounted">
        <li class="nav-item">
            <a :class="navLinkClasses" href="#" @click.stop.prevent="onClick">
                <slot name="title">
                    {{ title }}
                </slot>
            </a>
        </li>
    </portal>
    <div :class="contentClasses" v-show="activeTab">
        <slot name="default"></slot>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import IdeoTabs from './IdeoTabs.vue';
import { normalizeClasses } from '@/helpers/Utils';

@Options({
    name: 'ideo-tab'
})
export default class IdeoTab extends Vue
{
    public isMounted: boolean = false;

    @Prop()
    public title: string;

    @Prop({ default: false })
    public active: boolean;

    @Prop({ default: false })
    public manual: boolean;

    public get tabs(): IdeoTabs
    {
        return this.$parent as IdeoTabs;
    }

    public get index(): number
    {
        return this.tabs.tabIndex(this);
    }

    public get activeTab(): boolean
    {
        return this.tabs.tabActive(this);
    }

    public get navLinkClasses(): Record<string, boolean>
    {
        return {
            'nav-link': true,
            'active': this.activeTab,
            'manual': this.manual,
            ...(this.activeTab ? normalizeClasses(this.tabs.activeNavItemClass) : {})
        };
    }

    public get contentClasses(): Record<string, boolean>
    {
        return {
            ...(this.activeTab ? normalizeClasses(this.tabs.activeTabClass) : {})
        };
    }

    public created(): void
    {
        this.tabs.registerTab(this);
    }

    public mounted(): void
    {
        this.isMounted = true;
    }

    public unmounted(): void
    {
        this.tabs.unregisterTab(this);
    }

    @Emit('click')
    public onClick(): number
    {
        if (this.manual)
            return;

        this.tabs.setActiveTab(this);

        return this.index;
    }
}
</script>
<style lang="scss" scoped>
.lesson-form, .test-form{
    .nav-tabs .nav-link{
        border: 0;
    }
}

</style>
