import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import RecaptchaService from '@/modules/core/common/services/RecaptchaService';
import { useRecaptcha } from '@/plugins/recaptcha';

const { $recaptcha } = useRecaptcha();

export const Definition: BlueprintDefinition = {
    type: 'recaptcha',
    name: '[[[Recaptcha]]]',
    icon: 'fa-refresh',
    group: 'special',
    position: 13
};

export class RecaptchaEntry extends ValidEntry<string>
{
    public type: string = Definition.type;
    public data: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: RecaptchaContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        if (!this.data)
        {
            const recaptchaOptions = await RecaptchaService.fetch();

            await $recaptcha.$install(recaptchaOptions.publicKey, recaptchaOptions.isActive);

            if ($recaptcha.active)
            {
                await $recaptcha.$execute('login',
                    (captchaResult: string): void =>
                    {
                        this.data = captchaResult;
                    },
                    (ex: any) =>
                    {
                        // on reject captcha
                        this.data = '';
                    });
            }
            else
            {
                this.data = '';
            }
        }

        return entry({
            type: this.type,
            data: this.data
        });
    }

    public validate(blueprint: RecaptchaContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        return this.valid();
    }
}

export const instanceOfRecaptchaEntry = (object: any): object is RecaptchaEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export interface RecaptchaContract extends Blueprint, VisibleBlueprint, HasWidth, HasLabel, HasHelp
{
}

export class RecaptchaType implements RecaptchaContract, ValidatableBlueprint, EntryFactory<RecaptchaEntry>, HasHelp
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public width: number;
    public minWidth: number;
    public errors: ValidationErrors;
    public help: Record<string, string>;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Recaptcha' };
        this.showLabel = true;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.width = Math.min(3, Math.max(this.minWidth, width));
        this.minWidth = 1;
        this.errors = {};
        this.help = {};
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): RecaptchaEntry
    {
        return new RecaptchaEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
