<script setup lang="ts">
import { TreeNodeCoursesProgram } from '../utils';

defineOptions({
    name: 'ideo-tree-courses-program'
});

defineProps({
  "pages": null,
  "expanded": null,
  "archive": { type: Boolean, default: false },
  "menu": { type: Function, default: null },
  "move": { type: Function, default: () => false },
  "iconVisible": { type: Boolean, default: false },
  "userProgram": { type: Boolean, default: false }
});

const emit = defineEmits(["toggle", "click", "sort", "restore", "resetUnit", "delete"]);

const onToggle = (node: TreeNodeCoursesProgram): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNodeCoursesProgram): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNodeCoursesProgram[]): void =>
{
    emit('sort', pages);
};

const onRestore = (node: TreeNodeCoursesProgram): void =>
{
    emit('restore', node);
};

const resetUnitResult = (node: TreeNodeCoursesProgram): void =>
{
    emit('resetUnit', node);
};

const onDelete = (node: TreeNodeCoursesProgram): void =>
{
    emit('delete', node);
};

</script>

<template>
    <ideo-tree-courses-program-nodes :pages="pages" :archive="archive" :expanded="expanded" :user-program="userProgram" :parent="null" :menu="menu" :move="move" :icon-visible="iconVisible" @toggle="onToggle" @click="onClick" @sort="onSort" @restore="onRestore" @resetUnit="resetUnitResult" @delete="onDelete" class="tree tree-courses-program" />
</template>

<style lang="scss">
.tree-courses-program {
    li > span i {
        font-size: 18px;
    }
}
</style>
<style lang="scss" scoped>
$icon-color: var(--bs-green);
$link-color: #434E59 !default;
$link-muted: #c0cadd !default;
$icon-size: 16px !default;

.tree {
    padding-left: 0;
    list-style: none;

    ul {
        padding-left: 1.5em;
        list-style: none;
    }
    li {
        position: relative;
        margin: 0.5rem 0;
        padding: 0;

        > span {
            padding: 0.5rem 0;
            a {
                color: var(--bs-link-color);
                padding: 0 0.5em 0 0.5em;
                font-weight: 500;
            }
            .fa-plus-square,
            .fa-minus-square,
            .fa-cog,
            .fa-trash {
                cursor: pointer;
            }
            .fad:after {
                opacity: 1;
            }
        }

        .popover-body {
            min-width: 150px;
        }
        .dropdown-menu {
            position: unset;
            display: block;
            border: none;
        }
    }
}

.tree:not(.tree-nested) {
    li {
        &:hover {
            background-color: var(--color-header);
        }
        ul.tree-nested {
            background-color: #fff;
            li:hover {
                background-color: #e5e5e5;
                .fa-cog {
                    color: #afafaf;
                }
            }
        }
    }
}
</style>
