<template>
    <ideo-form-group :invalid-feedback="form.$errors.first('settings.showTitle')" :state="form.$errors.state('settings.showTitle')" :nospace="true">
        <ideo-form-checkbox v-model="form.settings.showTitle">{{ $t('[[[Wyświetlaj tytuł]]]') }}</ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Wybierz listę regulaminów]]]')" :invalid-feedback="form.$errors.first('settings.regulationsSetId')" :state="form.$errors.state('settings.regulationsSetId')">
        <ideo-form-select name="regulationsSetId" v-model="form.settings.regulationsSetId" :options="regulationsSets" value-field="id" text-field="name" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Wybierz kategorię subskrypcji]]]')" :invalid-feedback="form.$errors.first('settings.categoryId')" :state="form.$errors.state('settings.categoryId')">
        <ideo-form-select name="categoryId" v-model="form.settings.categoryId" :options="categories" value-field="id" text-field="name" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('settings.content')" :state="form.$errors.state('settings.content')">
        <editor id="editorContent" v-model="form.settings.content"></editor>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import { RegulationSetTypeName } from '@/helpers/Enums';
import RegulationsSetsService, { ListItemModel as RegulationsSetListItemModel } from '@/modules/cms/regulations/services/RegulationsSetsService';
import SubscriptionsCategoriesService, { ListItemModel as CategoryListItemModel } from '@/modules/cms/subscriptions/services/SubscriptionsCategoriesService';
import merge from 'lodash/merge';

@Options({
    name: 'widget-newsletter-settings'
})
export default class NewsletterSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        showTitle: false,
        regulationsSetId: null,
        categoryId: null,
        content: ''
    };

    public regulationsSets: RegulationsSetListItemModel[] = [];
    public categories: CategoryListItemModel[] = [];

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.loadRegulationsSets();
        this.loadCategories();

        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public async loadRegulationsSets(): Promise<boolean>
    {
        try
        {
            const response = await RegulationsSetsService.getAll();

            this.regulationsSets = [{
                id: null,
                name: this.$t('[[[Brak listy regulaminów]]]'),

            }].concat(response.result);

            for (const item of this.regulationsSets)
            {
                item.name = item.name ?? RegulationSetTypeName(item.type);
            }
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.form.$errors.push('parentId', this.$t('[[[Nie udało się pobrać listy regulaminów]]]'))
            });

            return false;
        }

        return true;
    }

    public async loadCategories(): Promise<boolean>
    {
        try
        {
            const response = await SubscriptionsCategoriesService.getAll();

            this.categories = [{
                id: null,
                name: this.$t('[[[Brak kategorii]]]'),
                position: 0
            }].concat(response.result);
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.form.$errors.push('parentId', this.$t('[[[Nie udało się pobrać kategorii]]]'))
            });

            return false;
        }

        return true;
    }
}
</script>
