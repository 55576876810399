<script lang="ts" setup>
import { ref, computed, Ref, onBeforeMount } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { TextFieldTypes, TextEntry, instanceOfTextEntry, ValidationTypes, TextType } from '.';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'text-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new TextEntry()) as Ref<TextEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfTextEntry, props.index);

onBeforeMount(() =>
{
    entryData.value.isHeader = props.blueprint.isHeader;
    entryData.value.isSummary = props.blueprint.isSummary;
});

const value = computed({
    get()
    {
        return entryData.value.data === null
            ? props.form.expressions.executeExpression(props.blueprint.defaultValue)
            : entryData.value.data;
    },
    set(value: string)
    {
        entryData.value.data = value;
    }
});

const fieldTypeOptions: Option<TextFieldTypes>[] = [
    { value: TextFieldTypes.Text, text: '[[[Tekst]]]' },
    { value: TextFieldTypes.Multiline, text: '[[[Treść]]]' },
    { value: TextFieldTypes.Password, text: '[[[Hasło]]]' }
];

const validationOptions: Option<ValidationTypes>[] = [
    { value: ValidationTypes.None, text: '[[[Brak]]]' },
    { value: ValidationTypes.Alphabetic, text: '[[[Litery]]]' },
    { value: ValidationTypes.Numeric, text: '[[[Cyfry]]]' },
    { value: ValidationTypes.Alphanumeric, text: '[[[Litery i cyfry]]]' },
    { value: ValidationTypes.RegularExpression, text: '[[[Wyrażenie regularne]]]' }
];

const isTextField = computed(() => props.blueprint.fieldType === TextFieldTypes.Text);
const isMultiline = computed(() => props.blueprint.fieldType === TextFieldTypes.Multiline);
const isPassword = computed(() => props.blueprint.fieldType === TextFieldTypes.Password);
const isRegExpValidation = computed(() =>
{
    return isTextField.value && props.blueprint.validation === ValidationTypes.RegularExpression;
});

const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));
</script>

<template>
    <form-component-wrapper class="text-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-input-group :prepend="blueprint.affix.prepend" :append="blueprint.affix.append">
                    <input type="text" class="form-control" v-model="value" :id="blueprint.id" :placeholder="placeholder" :readonly="readonly" v-if="isTextField">
                    <textarea class="form-control" v-model="value" :id="blueprint.id" :placeholder="placeholder" :readonly="readonly" :rows="blueprint.rows" v-if="isMultiline"></textarea>
                    <input type="password" class="form-control" v-model="value" :id="blueprint.id" :placeholder="placeholder" :readonly="readonly" v-if="isPassword">
                </ideo-input-group>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="text" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <field-affix v-model="blueprint.affix" />
            <field-minmax v-model="blueprint.characters" :label="$t('[[[Liczba znaków]]]')" :naturals="true" />
            <field-numeric v-model="blueprint.rows" :label="$t('[[[Liczba wierszy]]]')" :naturals="true" :min="2" :max="20" v-if="isMultiline" />
            <field-select v-model="blueprint.validation" :options="validationOptions" :label="$t('[[[Walidacja]]]')" v-if="isTextField" />
            <field-text v-model="blueprint.validationRule" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'validationRule')" :label="$t('[[[Wyrażenie regularne]]]')" v-if="isRegExpValidation" />
            <ideo-form-localize v-if="isRegExpValidation" v-slot="{ locale }">
                <field-text v-model="blueprint.validationMessage[locale]" :label="$t('[[[Komunikat walidacji]]]')" />
            </ideo-form-localize>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
